<template>
  <div>
    <v-expand-transition>
      <v-card
        v-if="myBankAccount"
        class="d-none d-sm-block pa-6 mb-4 elevation-5 text-right font-weight-black"
        color="grey"
      >
        Seu saldo é de R$ {{ formataMoedaBRL(myBankAccount.balance) }}
      </v-card>
    </v-expand-transition>
    
    <v-expand-transition>
      <v-card
        v-if="myTransactionList"
        class="d-none d-sm-block"
      >
        <v-toolbar color="primary">
          <v-toolbar-title>Transações</v-toolbar-title>
          <v-btn
            icon
            large
            link
            to="/"
            color="white"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-data-table
            items-per-page="15"
            :headers="headers"
            :items="myTransactionList"
            class="elevation-1"
            :search="filter"
          >
            <template #[`item.amount`]="{ item }">
              R$ {{ formataMoedaBRL(item.amount) }}
            </template>

            <template #[`item.transactionType`]="{ item }">
              <span v-if="item.transactionType.toLowerCase() == 'transfer_in'">Transferência recebida</span>
              <span v-else-if="item.transactionType.toLowerCase() == 'transfer_out'">Transferência para terceiros</span>
              <span v-else-if="item.transactionType.toLowerCase() == 'deposit'">Depósito</span>
              <span v-else-if="item.transactionType.toLowerCase() == 'withdrawal'">Saque</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-expand-transition>
      <v-card
        v-if="myBankAccount"
        class="elevation-5 d-block d-sm-none mb-4"
      >
        <v-toolbar color="primary">
          <v-toolbar-title>Minha conta</v-toolbar-title>
          <v-btn
            icon
            large
            link
            to="/"
            color="white"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="">
          <div class="text-body-1 font-weight-black">
            {{ store.userLogged.fullName }}
          </div>
          <div class="text-body-2">
            Conta criada em {{ myBankAccount.dtCreated }}
          </div>
          <div class="text-body-2">
            Número da conta: {{ myBankAccount.accountNumber }}
          </div>
          <div><v-divider class="mt-2 mb-2" /></div>
          <div
            class="text-body-2 font-weight-bold"
            style="color : green;"
          >
            Saldo: {{ 'R$ ' + formataMoedaBRL(myBankAccount.balance) }}
          </div>
        </v-card-text>
      </v-card>
    </v-expand-transition>


    <v-expand-transition>
      <v-card
        v-if="myTransactionList"
        class="elevation-5 d-block d-sm-none"
      >
        <v-toolbar color="primary">
          <v-toolbar-title>Movimentações</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="">
          <v-list
            class=""
            lines="2"
          >
            <v-list-item
              v-for="(item, index) in myTransactionList"
              :key="item.id"
              :title="'R$ ' + formataMoedaBRL(item.amount)"
              :subtitle="item.dtCreated"
            >
              <div>
                <span v-if="item.transactionType.toLowerCase() == 'transfer_in'">Transferência recebida</span>
                <span v-else-if="item.transactionType.toLowerCase() == 'transfer_out'">Transferência para terceiros</span>
                <span v-else-if="item.transactionType.toLowerCase() == 'deposit'">Depósito</span>
                <span v-else-if="item.transactionType.toLowerCase() == 'withdrawal'">Saque</span>
              </div>
              <v-divider
                v-if="isNotFirstItem(index, myTransactionList.length)"
                class="mb-1 mt-2"
              />
            </v-list-item>
          </v-list>
        </v-card-text>
        <!-- <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn variant="outlined" color="primary"
            class="" text :width="isSmallScreen ? '100%' : ''">
            Click here
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {

  components : {
  },

  mixins: [
    myMixin,
  ],

  data: () => ({

    headers : [
      { title: 'Data', key: 'dtCreated', sortable: true},
      { title: 'Titular', key: 'ownerName', sortable: true},
      { title: 'Conta', key: 'accountNumber', sortable: false},
      { title: 'Tipo', align: 'center', key: 'transactionType', sortable: true},
      { title: 'Valor', align: 'center', key: 'amount', sortable: false},
    ],

    filter : null,
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    myTransactionList : null,
    myBankAccount : null,
  }),  

  computed : {
    
  },

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    this.loadMyTransactions();
    this.loadUserBankAccount();
    this.loadBasicGlobalData();
  },

  methods: {

    async loadUserBankAccount(){

      if(store.userLogged){
        this.axios.get(services.serviceHost + "/bankaccount/user/load", {
          headers: { Authorization: `Bearer ${store.currentToken}` }
        })
        .then(response => {
            //console.log('user bank account: \n' + JSON.stringify(response.data));
            this.myBankAccount = response.data;
        })
        .catch(error => {
          console.log('user bank account : ' + error.status);
          store.dialogLoader = false;
          store.showDialogError;
          this.softLogout();
        });
      }
    },

    async loadMyTransactions(){

      if(store.userLogged){
        store.dialogLoader = true;
        this.axios.get(services.serviceHost + "/bankaccount/user/transaction/all", {
          headers: { Authorization: `Bearer ${store.currentToken}` }
        })
        .then(response => {
            //console.log('user transactions: \n' + JSON.stringify(response.data));
            this.myTransactionList = response.data;
            store.dialogLoader = false;
        })
        .catch(error => {
          console.log('user loadMyTransactions : ' + error.status);
          store.dialogLoader = false;
          store.showDialogError;
          this.softLogout();
        });
      }
    },

    isNotFirstItem(index, length) {
      // Check if the current index is greater than 0
      return index < length - 1;
    },

      

  }

}

</script>

<style scoped>


</style>
