<template>
  <v-row justify="center">
    <v-col
      cols="12"
      xl="3"
      lg="5"
      md="6"
      sm="8"
    >
      <v-col
        v-if="store.pedidoSaving"
        cols="12 mb-sm-n8 text-center font-weight-bold"
      >
        Faça login ou crie sua conta para prosseguirmos com seu pedido de serviço de {{ store.pedidoSaving.servico.nomeServico }}. 
      </v-col>
      <v-col
        v-if="store.pedidoProposal"
        cols="12 mb-sm-n8 text-center font-weight-bold"
      >
        Faça login ou crie sua conta para prosseguirmos com sua proposta de serviço de {{ store.pedidoProposal.servico.nomeServico }}. 
      </v-col>
      <v-card
        class="mx-2 mt-md-16 elevation-10"
        :disabled="store.dialogLoader"
      >
        <v-toolbar color="primary">
          <v-toolbar-title>
            <v-icon class="mr-2">
              mdi-account
            </v-icon>Login
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-5">
          <v-text-field
            id="fieldn"
            v-model="form.fieldn"
            class="mb-5 mt-4" 
            clearable
            variant="outlined"
            label="Email"
            maxlength="100"
            :error-messages="v$.form.fieldn.$invalid ? ['Digite seu email'] : []"
          />

          <v-expand-transition>
            <v-text-field
              v-if="!v$.form.fieldn.$invalid"
              id="fieldp" 
              v-model="form.fieldp"
              class="mb-5"
              variant="outlined"
              :disabled="v$.form.fieldn.$invalid"
              label="Senha"
              maxlength="20"
              :type="show1 ? 'text' : 'password'"
              :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              counter
              :error-messages="v$.form.fieldp.$invalid ? ['Digite a senha'] : []"
              @click:append-inner="show1 = !show1"
              @keyup.enter="doLogin()"
            />
          </v-expand-transition>

          <div
            v-if="store.loginErrorMessage"
            class="text-center"
            transition="slide-x-transition"
          >
            <span
              class=""
              style="color : red;"
            >
              {{ store.loginErrorMessage }}
            </span>
          </div>

          <v-expand-transition>
            <div
              v-if="!v$.form.$invalid"
              class=""
            >
              <v-btn
                color=""
                prepend-icon="mdi-check"
                class="mt-4 elevation-0"
                large 
                :disabled="v$.form.$invalid" 
                :style="btnCardStyle"
                elevation="2"
                width="100%"
                min-height="70"
                @click="doLogin()"
              >
                Entrar
              </v-btn>
            </div>
          </v-expand-transition>

          <v-expand-transition>
            <div
              v-if="!v$.form.fieldn.$invalid"
              class="mt-4"
            >
              <v-btn
                color=""
                class="px-4 elevation-0"
                large
                link
                to="/account/new"
                :style="btnCardStyle"
                elevation="2"
                width="100%"
                min-height="70"
              >
                Criar Conta
              </v-btn>
            </div>
          </v-expand-transition>

          <v-expand-transition>
            <div class="mt-4">
              <v-btn
                v-if="!v$.form.fieldn.$invalid"
                color="white"
                width="100%"
                text
                size="small"
                class="elevation-0"
                @click="store.dialogRecoverPwd = true"
              >
                esqueci minha senha
              </v-btn>
            </div>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { myMixin } from '../mixin';
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import services from '../ws/services'
import { store } from '../store'

import { aesUtil } from '../aes'


export default {

  mixins: [
    myMixin,
  ],

  setup () {
    return { v$: useVuelidate() }
  },

  data: () => ({
      
    fieldUser : null,
    fieldPwd : null,

    show1: false,

    form: {
      fieldn: '',
      fieldp: '',
    },

    //stored items:
    storedUsername : null,
    storedPassword : null,

    store,

  }),

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
  },

  beforeMount(){
    var storage = window.localStorage;
    this.storedUsername = storage.getItem('username');

    if(this.storedUsername && this.storedUsername.length > 10){
      this.storedPassword = storage.getItem('password');

      this.form.fieldn = aesUtil.decryptString(services.myphrase, this.storedUsername);
      this.form.fieldp = aesUtil.decryptString(services.myphrase, this.storedPassword);
      this.doLogin();
    }
  },

  methods: {

    doLogin(){
      this.processLogin(this.form.fieldn, this.form.fieldp);
    },

    
  },

  validations() {
    return {
      form: {
        fieldn: {
           required, email 
        },
        fieldp: {
            required, 
            min: minLength(6)
        },
      },
    }
  },



}

</script>

<style scoped>


</style>
