export default class UserDetails {
  
    constructor() {}
  
        id = null;
        user = null;
        nascimento = null;
        cpf = null;
        telefones = [];
        enderecos = [];
        
        userId = null;



  }