<template>
  <v-card :disabled="store.dialogLoader">
    <v-toolbar
      color="primary"
      title="Categorias"
    >
      <v-spacer />
      <v-btn
        icon
        @click="addItem"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="">
        <v-row justify="end">
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="filters['global'].value"
              placeholder="Filtrar..."
              maxlength="10"
              prepend-inner-icon="mdi-magnify"
              clearable
            />
          </v-col>
        </v-row>
      </div>

      <v-data-table v-if="itemList"
        v-model:search="filters['global'].value"
        :items="itemList"
        class="elevation-1"
        items-per-page="20"
      >
        <template v-slot:header.nomeCategoria>
          <div>Nome</div>
        </template>
        <template v-slot:header.dtCreated>
          <div>Data de Criação</div>
        </template>
        <template v-slot:header.edit>
          <div class="text-center">Editar</div>
        </template>
        <template v-slot:header.enabled>
          <div class="text-center">Ativo</div>
        </template>

        <template v-slot:item.nomeCategoria="{ item }">
          {{ item.nomeCategoria }}
        </template>

        <template v-slot:item.dtCreated="{ item }">
          {{ item.dtCreated }}
        </template>

        <template v-slot:item.edit="{ item }">
          <v-btn
            icon
            color="primary"
            small
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.enabled="{ item }">
          <v-switch
            v-model="item.enabled"
            class="switch-center"
            color="primary"
            hide-details
            @change="changeItemStatus(item)"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>

  <ModalCategoria
    v-if="selectedItem"
    :key="selectedItem && selectedItem.id ? selectedItem.id : Math.random()"
    :show-dialog="showItemModal"
    :item-id="selectedItem.id"
    :title-message="selectedItem.id ? 'Editar Categoria' : 'Nova Categoria'"
    @item-modal-close="itemModalClose"
    @item-modal-save="itemModalSave($event)"
    @update-show-dialog="updateShowDialog($event)"
  />
</template>

<script>
import { myMixin } from "../../mixin";
import services from "../../ws/services";
import { store } from "../../store";
import ModalCategoria from "../modal/ModalCategoria.vue";
import Categoria from "../entity/Categoria";

export default {
  components: {
    ModalCategoria,
  },

  mixins: [myMixin],

  data: () => ({
    store,
    itemList: null,
    showItemModal: false,
    search: null,
    loading: false,
    selectedItem: null,

    headers: [
      { text: "Nome", key: "nomeCategoria", sortable: true },
      { text: "Data de Criação", key: "dtCreated", sortable: true },
      { text: "Editar", key: "edit", sortable: false },
      { text: "Ativo", key: "enabled", sortable: false },
    ],

    filters: {
      global: { value: null, matchMode: "contains" },
      nomeCategoria: { value: null, matchMode: "contains" },
    },
  }),

  computed: {
    cardTitleStyle() {
      return (
        "background-color : " +
        this.$vuetify.theme.themes.light.colors.primary +
        "; color : white;"
      );
    },
    btnCardStyle() {
      return (
        "background-color : " +
        this.$vuetify.theme.themes.light.colors.primary +
        "; color : white;"
      );
    },
  },

  mounted() {
    this.reloadItemList();
  },

  methods: {
    buildObject() {
      return new Categoria();
    },

    reloadItemList() {
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` },
      };
      this.axios
        .post(services.serviceHost + "/categoria/all", {}, config)
        .then(response => {
          this.itemList = response.data;
          store.dialogLoader = false;
        })
        .catch((error) => {
          console.log(error.message);
          store.dialogLoader = false;
          this.loading = false;
          store.showDialogError = true;
          this.logout();
        });
    },

    editItem(myItem) {
      console.log("editando item : " + myItem.nomeCategoria);
      this.selectedItem = myItem;
      this.showItemModal = true;
    },

    addItem() {
      console.log("adicionando item");
      this.selectedItem = this.buildObject();
      this.showItemModal = true;
    },

    changeItemStatus(myItem) {
      store.dialogLoader = true;

      this.axios({
        method: "post",
        url: services.serviceHost + "/categoria/changestatus" + "/" + myItem.id,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`,
        },
        data: myItem,
      })
        .then(() => {
          console.log("atualizou item: " + myItem.id);
          this.reloadItemList();
          store.globalSnackbarMessage = "Item atualizado";
          store.globalSnackbar = true;
        })
        .catch((error) => {
          console.log(error.message);
          this.showItemModal = false;
          store.showDialogError = true;
          this.logout();
        });
    },

    itemModalSave(myItem) {
      console.log("vai salvar item: " + myItem.nomeCategoria);
      this.showItemModal = false;
      this.selectedItem = null;

      this.axios({
        method: "post",
        url: services.serviceHost + "/categoria/save",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`,
        },
        data: myItem,
      })
        .then(() => {
          console.log("salvou item: " + myItem.id);
          store.dialogLoader = false;
          this.reloadItemList();
          store.globalSnackbarMessage = "Item salvo";
          store.globalSnackbar = true;
        })
        .catch((error) => {
          console.log(error.message);
          this.showItemModal = false;
          store.showDialogError = true;
          this.logout();
        });
    },

    itemModalClose() {
      console.log("fechou a modal");
      this.showItemModal = false;
      this.selectedItem = null;
      this.reloadItemList();
    },

    updateShowDialog(val) {
      console.log("update show dialog: " + val);
    },
  },
};
</script>

<style scoped></style>
