<template>
  <v-card>
    <v-layout>
      <v-app-bar
        color="primary"
        prominent
      >
        <v-app-bar-nav-icon variant="text" 
        @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title class="d-none d-sm-flex link-no-style">
          <v-btn class="text-none px-4 no-active" 
            size="x-large" link to="/">
            {{ services.myAppName }} :: Plataforma de Serviços
          </v-btn>
          <!-- <a class="link-no-style" href="/">{{ services.myAppName }} :: Plataforma de Serviços</a> -->
        </v-toolbar-title>
        <v-toolbar-title class="d-sm-none d-flex">
          <v-btn class="text-none pa-0" size="x-large" link to="/">
            {{ services.myAppName }}
          </v-btn>
          <!-- <a class="link-no-style" href="/">{{ services.myAppName }}</a> -->
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-divider v-if="!store.userLogged" vertical class="sm-flex d-none ml-3 mr-3" />
        <v-btn v-if="!store.userLogged" class="d-none d-sm-flex" link to="/account/new">
          Criar Conta
        </v-btn>

        <v-divider vertical class=" ml-3 mr-3" />
        <v-btn v-if="!store.userLogged" link to="/login">
          Login
        </v-btn>

        <v-menu class="d-none d-sm-flex">
          <template #activator="{ props }">
                  <v-btn
                    v-if="store.userLogged"
                    prepend-icon="mdi-account"
                    class="px-4 d-none d-sm-flex"
                    rounded="lg"
                    large
                    v-bind="props"
                  >
                    {{ store.userLogged.fullName }}
                  </v-btn>
                </template>
          <v-list density="compact">
            <v-list-item link to="/user/account/detail">
              <v-list-item-title>Meu Perfil</v-list-item-title>
            </v-list-item>

            <v-list-item @click="changeMyPassword = true">
              <v-list-item-title>Alterar Senha</v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu class="d-sm-none d-flex">
          <template #activator="{ props }">
                  <v-btn
                    v-if="store.userLogged"
                    icon
                    class="px-4 d-sm-none d-flex"
                    rounded="lg"
                    large
                    v-bind="props"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
          <v-list density="compact">
            <v-list-item link to="/user/account/detail">
              <v-list-item-title>Meu Perfil</v-list-item-title>
            </v-list-item>

            <v-list-item @click="changeMyPassword = true">
              <v-list-item-title>Alterar Senha</v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider v-if="store.userLogged && store.notificationUnreadList && store.notificationUnreadList.length > 0" vertical
          class=" ml-3 mr-3" />

        <v-expand-x-transition>
          <v-btn v-if="store.userLogged && store.notificationUnreadList && store.notificationUnreadList.length > 0" link
            rounded="lg" large icon to="/user/notification/all">
            <v-icon v-if="store.totalNotificationsUnread < 1">
              mdi-bell-outline
            </v-icon>
            <v-icon v-if="store.totalNotificationsUnread >= 1" color="#FFEC33">
              mdi-bell-ring-outline
            </v-icon>
          </v-btn>
        </v-expand-x-transition>

      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        :location="$vuetify.display.mobile ? 'left' : undefined"
        temporary
        width="400"
      >
        
        <!-- menu lateral  -->
        <v-list
          density="compact"
        >
          <v-list-item
            to="/"
            link
            class=""
            @click="drawer = false"
          >
            <template #prepend>
              <v-icon class="mr-3">
                mdi-home
              </v-icon>
            </template>

            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <!-- menu do admin -->
          <v-divider class="" />

          <v-list-group
            v-if="store.userLogged && store.userLogged.roleAdmin"
          >
            <template #activator="{ props }">
              <v-list-item
                v-bind="props"
                title="Administrador"
              >
                <template #prepend>
                  <v-icon class="mr-3">
                    mdi-account-tie
                  </v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item
              class=""
              title="Pedidos"
              to="/admin/pedido/list"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-wrench-outline
                </v-icon>
              </template>
            </v-list-item>


            <v-list-item
              class=""
              title="Propostas"
              to="/admin/proposta/list"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-file-document-edit-outline
                </v-icon>
              </template>
            </v-list-item>

            <v-list-item
              class=""
              title="Serviços"
              to="/admin/servico"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-tools
                </v-icon>
              </template>
            </v-list-item>

            <v-list-item
              class=""
              title="Categorias"
              to="/admin/categoria"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-format-list-bulleted
                </v-icon>
              </template>
            </v-list-item>

            <v-list-item
              class=""
              title="Usuários"
              to="/admin/users"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-account-multiple
                </v-icon>
              </template>
            </v-list-item>
          </v-list-group>

          <v-list-group
            v-if="store.userLogged && (store.userLogged.roleAdmin || store.userLogged.roleFinanceiro)"
            
          >
            <template #activator="{ props }">
              <v-list-item
                v-bind="props"
                title="Financeiro"
              >
                <template #prepend>
                  <v-icon class="mr-3">
                    mdi-cash-100
                  </v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item
              class=""
              title="Dashboard"
              to="/financeiro/dashboard"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-monitor-dashboard
                </v-icon>
              </template>
            </v-list-item>

            <v-list-item
              class=""
              title="Transações do Sistema"
              to="/financeiro/transactions/all"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-swap-horizontal-bold
                </v-icon>
              </template>
            </v-list-item>
          </v-list-group>

          <v-list-group
            v-if="store.userLogged"
          >
            <template #activator="{ props }">
              <v-list-item
                v-bind="props"
                title="Minha Conta"
              >
                <template #prepend>
                  <v-icon class="mr-3">
                    mdi-account
                  </v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item
              class=""
              title="Dados Cadastrais"
              to="/user/account/detail"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-clipboard-account-outline
                </v-icon>
              </template>
            </v-list-item>

            <v-list-item
              v-if="store.myPedidosCount > 0"
              class=""
              title="Meus Pedidos"
              to="/user/pedido/list"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-account-tie-voice-outline
                </v-icon>
              </template>
            </v-list-item>

            <v-list-item
              v-if="store.myPropostasCount > 0"
              class=""
              title="Minhas Propostas"
              to="/user/proposta/list"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-hammer-wrench
                </v-icon>
              </template>
            </v-list-item>

            <v-list-item
              class=""
              title="Depositar"
              to="/user/bankaccount/addmoney"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-cash
                </v-icon>
              </template>
            </v-list-item>

            <v-list-item
              v-if="store.notificationUnreadList && store.notificationUnreadList.length > 0"
              class=""
              title="Notificações"
              to="/user/notification/all"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-message-alert-outline
                </v-icon>
              </template>
            </v-list-item>

            <!-- <v-list-item class="" @click="logout(); drawer = false" v-if="store.userLogged"
              title="Logout"
            >
              <template v-slot:prepend>
                <v-icon class="mr-3">mdi-arrow-left-circle-outline</v-icon>
              </template>
            </v-list-item> -->
          </v-list-group>

          <v-list-group
            v-if="store.userLogged && store.totalUserTransactions && store.totalUserTransactions > 0"
          >
            <template #activator="{ props }">
              <v-list-item
                v-bind="props"
                title="Minha Carteira"
              >
                <template #prepend>
                  <v-icon class="mr-3">
                    mdi-cash-multiple
                  </v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item
              class=""
              title="Minhas Transações"
              to="/user/bankaccount/all"
              @click="drawer = false"
            >
              <template #prepend>
                <v-icon class="mr-3">
                  mdi-account-cash-outline
                </v-icon>
              </template>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-if="store.userLogged"
            class=""
            title="Logout"
            @click="logout(); drawer = false"
          >
            <template #prepend>
              <v-icon class="mr-3">
                mdi-arrow-left-circle-outline
              </v-icon>
            </template>
          </v-list-item>
        </v-list>

      </v-navigation-drawer>

      <v-main>
        <v-container fluid class="px-5">
          <router-view v-slot="{ Component }">
            <transition name="fade">
              <component :is="Component" />
            </transition>
          </router-view>
        </v-container>
      </v-main>


    </v-layout>

    <!-- dialog de erro -->
    <v-dialog
      v-model="store.showDialogError"
      transition="dialog-bottom-transition"
      width="auto"
    >
      <v-card>
        <v-toolbar
          color="error"
          title="Algo deu errado..."
        />
        <v-card-text>
          <div class="body-1 pa-6 text-center">
            Opa, desculpe. Algo de errado aconteceu aqui. <br>
            Pode ter sido um problema na conexão, na rede ou no nosso sistema. <br>
            Aguarde um pouco e tente novamente, ok?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="store.showDialogError = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- atualiza pass -->
    <v-dialog
      v-if="store.userLogged"
      v-model="store.userLogged.redefinePassword"
      transition="dialog-bottom-transition"
      width="450"
      persistent
    >
      <v-card :disabled="store.dialogLoader">
        <v-toolbar
          color="primary"
          title="Atualizar senha"
        />
        <v-card-text>
          <div class="body-1 pa-6 text-center">
            Você acessou a plataforma com uma senha provisória.<br> Vamos atualizar sua senha?
          </div>

          <v-text-field
            id="newPwd"
            v-model="newPwd" 
            class="mb-5"
            variant="outlined"
            label="Senha"
            maxlength="15"
            :type="show1 ? 'text' : 'password'"
            :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            counter
            :error-messages="v$.newPwd.$invalid ? ['Digite a senha'] : []"
            @click:append-inner="show1 = !show1"
          />

          <v-text-field
            id="confirmNewPwd"
            v-model="confirmNewPwd" 
            class="mb-5"
            variant="outlined"
            :disabled="v$.newPwd.$invalid"
            label="Confirme a Senha"
            maxlength="15"
            :type="show2 ? 'text' : 'password'"
            :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            counter
            :error-messages="v$.confirmNewPwd.$invalid ? ['Confirme a senha'] : []"
            @click:append-inner="show2 = !show2"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer />
          <v-btn
            prepend-icon="mdi-check"
            class="px-4"
            rounded="lg"
            large 
            :disabled="v$.confirmNewPwd.$invalid"
            :style="btnCardStyle"
            elevation="2"
            width="100%"
            @click="updatePwd"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-if="store.userLogged"
      v-model="changeMyPassword" 
      transition="dialog-bottom-transition"
      width="450"
    >
      <v-card :disabled="store.dialogLoader">
        <v-toolbar
          color="primary"
          class="pr-5"
        >
          <v-toolbar-title>
            Redefinir Senha
          </v-toolbar-title>
          <v-spacer />
          <v-icon>mdi-key-chain-variant</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            id="newPwd"
            v-model="newPwd" 
            class="mb-5"
            variant="outlined"
            label="Senha"
            maxlength="20"
            :type="show1 ? 'text' : 'password'"
            :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            counter
            :error-messages="v$.newPwd.$invalid ? ['Digite a senha'] : []"
            @click:append-inner="show1 = !show1"
          />

          <v-text-field
            id="confirmNewPwd"
            v-model="confirmNewPwd" 
            class="mb-5"
            variant="outlined"
            :disabled="v$.newPwd.$invalid"
            label="Confirme a Senha"
            maxlength="20"
            :type="show2 ? 'text' : 'password'"
            :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            counter
            :error-messages="v$.confirmNewPwd.$invalid ? ['Confirme a senha'] : []"
            @click:append-inner="show2 = !show2"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="px-4" 
            large
            text
            flat
            @click="changeMyPassword = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            prepend-icon="mdi-check"
            class="px-4"
            large
            text
            color="primary"
            flat
            :disabled="v$.confirmNewPwd.$invalid"
            @click="updatePwd"
          >
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- loader -->
    <v-dialog
      v-model="store.dialogLoader"
      :scrim="false"
      persistent
      width="300"
    >
      <v-card
        color="primary"
      >
        <v-card-text>
          Aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="store.dialogRecoverPwd"
      persistent
      transition="dialog-bottom-transition"
      width="500"
    >
      <v-card :disabled="store.dialogLoader">
        <v-toolbar
          color="primary"
          class="pr-4"
        >
          <v-toolbar-title>Recuperar Senha</v-toolbar-title>
          <v-icon>mdi-key-chain-variant</v-icon>
        </v-toolbar>
        <v-card-text class="pa-10">
          <v-text-field
            id="email"
            v-model="emailRecoverPwd"
            class="mb-5"
            clearable
            color="primary"
            prepend-icon="mdi-email-outline"
            label="Email de Cadastro"
            placeholder="Entre com seu email"
            maxlength="150"
            :error-messages="v$.emailRecoverPwd.$invalid ? ['Digite um email válido'] : []"
            @click:clear="errorMessageMail = null"
          />
        </v-card-text>
        <v-card-actions class="">
          <v-btn 
            variant="text"
            color="primary"
            @click="store.dialogRecoverPwd = false"
          >
            Fechar
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="v$.emailRecoverPwd.$invalid"
            variant="text"
            color="primary"
            @click="sendRecoverMessage"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- global snackbar  -->
    <v-snackbar
      v-model="store.globalSnackbar"
      close-delay="2000"
      location="top"
      class="text-center"
    >
      <div class="text-center">
        {{ store.globalSnackbarMessage }}
      </div>
    </v-snackbar>

  </v-card>
</template> 

<script>
import router from './router'
import { store } from './store'
import services from './ws/services'
import { required, minLength, sameAs, email, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { myMixin } from './mixin';

import ModalCriaPedido from './components/modal/ModalCriaPedido.vue'

import { aesUtil } from './aes'

import 'leaflet-css';

export default {

  components : {
    ModalCriaPedido,

  },

  router,

  mixins: [
    myMixin,
  ],

  setup () {
    return { v$: useVuelidate() }
  },

  data: () => ({

    show1 : false,
    show2 : false,

    newPwd : null,
    confirmNewPwd : null,

    drawer: false,
    store,

    emailRecoverPwd : null,
    changeMyPassword : false,

    showNovoPedidoModal : false,

    services,

    isSmallScreen : false,

    intervalId: null,

    notificationMenuOpened : false,

  }),

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
  },

  watch : {

    notificationMenuOpened(newValue){
      //console.log('mudou menu : ' + newValue);
      if(!newValue){
        this.abriuNotificacoes();
      }
    }
  },

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;

    clearInterval(this.intervalId);

  },

  mounted (){

    document.addEventListener("deviceready", this.onDeviceReady, false);

    if(this.isSmallScreen){
      if(!store.userLogged && !store.correntToken){
        console.log('lets log-in!!');

        var storage = window.localStorage;
        let storedUsername = storage.getItem('username');
        
        if(storedUsername && storedUsername.length > 10){
          let storedPassword = storage.getItem('password');

          // this.form.fieldn = aesUtil.decryptString(services.myphrase, storedUsername);
          // this.form.fieldp = aesUtil.decryptString(services.myphrase, storedPassword);
          this.processLogin(aesUtil.decryptString(services.myphrase, storedUsername), aesUtil.decryptString(services.myphrase, storedPassword));
        }
      }
    }

    // colocar todos os métodos recorrentes aqui nessa estrutura:
    this.intervalId = setInterval(() => {
      this.time++;
      console.log('***** checking notifications at ' + new Date());
      this.loadNotifications();
    }, 300000);
  },

  methods : {

    abriuNotificacoes(){

      console.log('abriu notificacoes');
      this.markAllNotificationsRead();
    },

    onDeviceReady() {
      window.plugins.safearea.get(this.successCallback, this.errorCallback);
      //window.open = this.$cordova.InAppBrowser.open;
      console.log('pronto aqui');
      console.log("navigator.geolocation works well");
      
      
      window.plugins.navigator.geolocation.getCurrentPosition(this.gpsOnSuccess,
                                         [this.gpsOnError],
                                         [{ maximumAge: 3000, timeout: 5000, enableHighAccuracy: true }]);
      //window.open = cordova.InAppBrowser.open;
    },

    gpsOnSuccess(position){
      console.log('Latitude: '          + position.coords.latitude          + '\n' +
        'Longitude: '         + position.coords.longitude         + '\n' +
        'Altitude: '          + position.coords.altitude          + '\n' +
        'Accuracy: '          + position.coords.accuracy          + '\n' +
        'Altitude Accuracy: ' + position.coords.altitudeAccuracy  + '\n' +
        'Heading: '           + position.coords.heading           + '\n' +
        'Speed: '             + position.coords.speed             + '\n' +
        'Timestamp: '         + position.timestamp                + '\n');
    },

    gpsOnError(error){
      console.log('code: '    + error.code    + '\n' + 'message: ' + error.message + '\n');
    },
    
    successCallback(result) {
      console.log('plugins: \n' + JSON.stringify(window.plugins));
      console.log(result);
    },

    errorCallback(result) {
      console.log(result);
    },

    pedidoModalClose(){
        console.log('fechou a modal');
        this.showNovoPedidoModal = false;
    },

    sendRecoverMessage(){

      store.dialogLoader = true;
      this.axios({
        method: "post",
        url: services.serviceHost + "/user/resetsenha",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        params: {
          email : this.emailRecoverPwd,
        }
      }).then(response => {
        console.log('recover pwd, result : ' + response.satus);
        store.dialogLoader = false;
        store.dialogRecoverPwd = false;
        store.globalSnackbarMessage = "Verifique seu email"
        store.globalSnackbar = true;
        this.$router.push("/");
        //this.reloadUserData();
      })
      .catch(error => {
        console.log(error.message);
        store.dialogLoader = false;
        store.dialogRecoverPwd = false;
        store.showDialogError = true;
      });
    },

    updatePwd(){
      
      store.dialogLoader = true;

      this.axios({
        method: "post",
        url: services.serviceHost + "/user/atualizasenha",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        params: {
          id : store.userLogged.id,
          novaSenha : this.newPwd,
        }
      }).then(response => {
        store.userLogged = response.data;
        store.dialogLoader = false;
        store.globalSnackbarMessage = "Senha atualizada"
        store.globalSnackbar = true;
        this.changeMyPassword = false;
        this.reloadUserData();
      })
      .catch(error => {
        console.log(error.message);
        store.dialogLoader = false;
        store.showDialogError = true;
      });
    },

  },

  validations() {
    return {
      newPwd: {
        required,
        min: minLength(6)
      },
      confirmNewPwd: {
        required,
        min: minLength(6),
        sameAs: sameAs(this.newPwd)
      },
      emailRecoverPwd : {
        required, email, 
        min: minLength(6),
        max: maxLength(150),
      },
    }
  }
}
</script>

<style>

app {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.body {
  /* font-family: 'El Messiri', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.8s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}

.align-right{
  text-align:right;
}
.align-center{
  text-align:center;
}
.p-datatable th[class*="align-"] .p-column-header-content {
  display: inline-flex ;
}

.switch-center {
  display: flex;
  justify-content: center;
}

.link-no-style {
  text-decoration: none;
  color: inherit;
}

.floating-button {
  position: fixed;
  width: 100%; /* set a width for the div */
  margin: auto; /* set the margin to auto horizontally */
  text-align: center;
  bottom: 16px;
  z-index: 999;
}

.no-active.v-btn--active::before {
  opacity: 0;
}

.no-active:focus::before {
  opacity: 0 !important;
}




</style>