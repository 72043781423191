import { store } from '../store'
import services from '../ws/services'
import { getCurrentInstance } from 'vue'
//import moment from "moment";

import { aesUtil } from '../aes'

export const myMixin = {

    data : {
      instance : null,
    },

    created(){
      this.instance = getCurrentInstance();
    },

    methods : {

      loadBasicGlobalData(){
        this.loadMyPedidosComPropostaID();
        this.loadNotifications();
        this.countUnreadNotifications();
        this.countMyPedidos();
        this.countMyPropostas();
        this.loadMyPedidosAceitos();
        this.loadMyPedidosEmExecucao();
        this.countUserTransactions();
        this.loadUserCurrentBalance();
      },

      async loadUserCurrentBalance(){

        if(store.userLogged){
          
          this.axios.get(services.serviceHost + "/bankaccount/user/balance", {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          })
          .then(response => {
              console.log('user balance: ' + response.data);
              store.userBankBalance = response.data;
          })
          .catch(error => {
            console.log('user balance : ' + error.status);
            this.softLogout();
          });
        }
      },

      async countUserTransactions(){

        if(store.userLogged){
          
          this.axios.get(services.serviceHost + "/bankaccount/user/transaction/count", {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          })
          .then(response => {
              console.log('total de transacoes do usuario: ' + response.data);
              store.totalUserTransactions = response.data;
          })
          .catch(error => {
            console.log('count total UserTransactions : ' + error.status);
            this.softLogout();
          });
        }
      },

      finishJobPedido(jobDTO){

        if(store.userLogged){

          return new Promise((resolve, reject) => {
            store.dialogLoader = true;

            this.axios({
              method: "post",
              url: services.serviceHost + "/pedido/finishjob",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.currentToken}`
              },
              data: jobDTO
            })
            .then(response => {
                console.log('pedido finishJobPedido: ' + response.status);
              
                let result = response.data;
                const index = store.pedidosEmExecucaoList.findIndex(item => item.id === result.id);
                console.log('********* index: ' + index);

                store.globalSnackbarMessage = "Pedido Atualizado";
                store.globalSnackbar = true;
                //this.loadBasicGlobalData();
                //this.loadMyPedidosAceitos();
                store.dialogLoader = false;

                resolve(response.data);
            })
            .catch(error => {
              console.log('finishJobPedido : ' + error.status);
              store.showDialogError = true;
              this.softLogout();
              reject(error);
            });
          });
        }
      },

      startJobPedido(myPedido){

        if(store.userLogged){

          return new Promise((resolve, reject) => {
            store.dialogLoader = true;
            this.axios.get(services.serviceHost + "/pedido/start/" + myPedido.id, {
              headers: { Authorization: `Bearer ${store.currentToken}` }
            })
            .then(response => {
                console.log('pedido start: ' + response.status);
              
                let result = response.data;
                const index = store.pedidosEmAndamentoList.findIndex(item => item.id === result.id);
                console.log('********* index: ' + index);

                store.globalSnackbarMessage = "Pedido Atualizado";
                store.globalSnackbar = true;
                //this.loadBasicGlobalData();
                //this.loadMyPedidosAceitos();
                store.dialogLoader = false;

                resolve(response.data);
            })
            .catch(error => {
              console.log('count startJobPedido : ' + error.status);
              store.showDialogError = true;
              this.softLogout();
              reject(error);
            });
          });
        }
      },

      async loadMyPedidosAceitos(){

        if(store.userLogged){
          
          this.axios.get(services.serviceHost + "/pedido/get/all/accepted", {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          })
          .then(response => {
              console.log('meus pedidos \'accepted\': ' + response.status);
              store.pedidosEmAndamentoList = response.data;
          })
          .catch(error => {
            console.log('count accepted : ' + error.status);
            this.softLogout();
          });
        }
      },

      async loadMyPedidosEmExecucao(){

        if(store.userLogged){
          
          this.axios.get(services.serviceHost + "/pedido/get/all/running", {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          })
          .then(response => {
              console.log('meus pedidos \'running\': ' + response.status);
              store.pedidosEmExecucaoList = response.data;
          })
          .catch(error => {
            console.log('count pedidos running : ' + error.status);
            this.softLogout();
          });
        }
      },

      async loadMyPedidosComPropostaID(){

        if(store.userLogged){
          
          const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
            .post(services.serviceHost + "/proposta/allidsfrompropostas", {}, config)
            .then(response => {
              console.log('meus IDS: ' + response.data);
              store.myPropostasIDs = response.data;
          })
          .catch(error => {
            console.log('count propostas : ' + error.status);
            this.softLogout();
          });
        }
      },

      async countMyPropostas(){

        if(store.userLogged){
          
          const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
            .post(services.serviceHost + "/proposta/my/count", {}, config)
            .then(response => {
              console.log('total de propostas: ' + response.data);
              store.myPropostasCount = response.data;
          })
          .catch(error => {
            console.log('count propostas : ' + error.status);
            this.softLogout();
          });
        }
      },

      async countMyPedidos(){
        
        if(store.userLogged){
          const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
            .post(services.serviceHost + "/pedido/my/count", {}, config)
            .then(response => {
              console.log('total de pedidos: ' + response.data);
              store.myPedidosCount = response.data;
          })
          .catch(error => {
            console.log('count pedidos : ' + error.status);
            this.softLogout();
          });
        }
      },

      async loadNotifications(){

        if(store.userLogged){

          if(store.notificationUnreadList.length < 1){
            store.notificationUnreadList = [];
          }
          const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
            .post(services.serviceHost + "/notification/all", {}, config)
            .then(response => {
              //console.log('loading notifications : ' + response.status);
              store.notificationUnreadList = response.data;
          })
          .catch(error => {
            console.log('check notifications : ' + error.status);
            this.softLogout();
          });

        }
      },

      async markAllNotificationsRead(){

        if(store.userLogged){
          const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
            .post(services.serviceHost + "/notification/mark/all/read", {}, config)
            .then(response => {
              console.log('set notifications read : ' + response.status);
              this.loadNotifications();
          })
          .catch(error => {
            console.log('check notifications : ' + error.status);
            this.softLogout();
          });
        }
      },

      async countUnreadNotifications(){

        if(store.userLogged){
          const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
            .post(services.serviceHost + "/notification/count/unread", {}, config)
            .then(response => {
              console.log('count notifications unread : ' + response.status);
              store.totalNotificationsUnread = response.data;
          })
          .catch(error => {
            console.log('check notifications : ' + error.status);
            this.softLogout();
          });
        }
    },

      clearAllAndGoBackHome(){

        store.redirectPage = null;
        store.pedidoProposal = null;
        store.pedidoSaving = null;

        this.$router.push("/");
      },

      confirmaPedido(){

        store.dialogLoader = true;
        this.axios({
            method: "post",
            url: services.serviceHost + "/user/logged",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.currentToken}`
            },
            data: {}
          }).then(response => {
            //console.log('user logged : \n' + JSON.stringify(response.data));
            store.userLogged = response.data;
            store.dialogLoader = false;

            //store.globalSnackbarMessage = 'Pedido Salvo';
            //store.globalSnackbar = true;
            this.$emit('item-modal-close');
            this.$router.push(store.redirectPage);
          })
          .catch(error => {
            console.log('user logged : ' + error.status);
            //console.log('error : ' + error);
            store.dialogLoader = false;
            this.$router.push("/login");
          });
      },

      pedidoProposta(){

        store.dialogLoader = true;
        this.axios({
            method: "post",
            url: services.serviceHost + "/user/logged",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.currentToken}`
            },
            data: {}
          }).then(response => {
            //console.log('user logged : \n' + JSON.stringify(response.data));
            store.userLogged = response.data;
            store.dialogLoader = false;

            //store.globalSnackbarMessage = 'Pedido Salvo';
            //store.globalSnackbar = true;
            this.$emit('item-modal-close');
            this.$router.push(store.redirectPage);
          })
          .catch(error => {
            console.log('user logged : ' + error.status);
            //console.log('error : ' + error);
            store.dialogLoader = false;
            this.$router.push("/login");
          });
      },

      savePedido(){

        //console.log('salvando : \n', JSON.stringify(store.pedidoSaving));
        store.dialogLoader = true;
        this.axios({
            method: "post",
            url: services.serviceHost + "/pedido/create",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.currentToken}`
            },
            data: { pedido : store.pedidoSaving }
          }).then(response => {
            //console.log('user logged : \n' + JSON.stringify(response.data));
            console.log('pedido salvo : ' + response.status);
            store.dialogLoader = false;
            store.pedidoSaving = null;
            store.redirectPage = null;

            store.globalSnackbarMessage = 'Pedido Salvo';
            store.globalSnackbar = true;

            this.countMyPedidos();
            this.countMyPropostas();
            this.loadMyPedidosComPropostaID();

            this.$router.push('/user/pedido/list');
          })
          .catch(error => {
            console.log('user logged : ' + error.status);
            this.logout();
          });
      },

      processLogin(myUser, myPwd) {

        //show loader:
        //this.dialogLoader = true;
        store.dialogLoader = true;

        const formData = new FormData();
        formData.append("grant_type", "password");
        formData.append("username", myUser);
        formData.append("password", myPwd);

        this.axios
          .post(services.serviceHost + "/oauth/token", formData, {
            auth: {
              username: "client",
              password: "XzO20F647"
            }
          })
          .then(response => {
            //granvando token:
            store.currentToken = response.data.access_token;

            console.log('token: \n' + response.data.access_token);

            var storage = window.localStorage;
            storage.setItem('username', aesUtil.encryptString(services.myphrase, myUser));
            storage.setItem('password', aesUtil.encryptString(services.myphrase, myPwd));

            this.axios({
              method: "post",
              url: services.serviceHost + "/user/logged",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.currentToken}`
              },
              data: {}
            }).then(response2 => {
              store.userLogged = response2.data;
              store.dialogLoader = false;
              
              this.loadBasicGlobalData();
              console.log('roles: ' + store.userLogged.roleAdmin + ', ' + store.userLogged.rolePrestador);
              if(store.redirectPage){
                this.$router.push(store.redirectPage);

              }else if(!store.userLogged.roleAdmin && store.userLogged.rolePrestador){ // home do prestador
                this.$router.push('/pedido/list/all');

              }else if(store.userLogged.roleAdmin){ // home do prestador
                this.$router.push('/financeiro/dashboard');

              }else{
                this.$router.push('/');
              }

            })
              .catch(error2 => {
                console.log(error2.message);
                this.cleanLocalUserData();
                store.loginErrorMessage = "Usuário e senha não conferem.";
                store.dialogLoader = false;
              });

          })
          .catch(error => {
            console.log(error.message);
            this.cleanLocalUserData();
            store.loginErrorMessage = "Usuário e senha não conferem.";
            store.dialogLoader = false;
          });
      },

      buscaCEP(myCEP) {

        store.dialogLoader = true;
        if (myCEP && myCEP.length == 9) {

          //https://opencep.com/v1/20531005.json
          this.axios
            .get('https://opencep.com/v1/' + myCEP.replaceAll('-', '') + '.json', {}, {})
            .then(response => {
              //console.log('resultado do CEP : \n' + JSON.stringify(response.data));
              store.dialogLoader = false;
              let myLoadedCEP = response.data;
              return myLoadedCEP;
            })
            .catch(error => {
              console.log('token inválido : ' + error);
              store.dialogLoader = false;
              store.userLogged = null;
              store.correntToken = null;
              return null;
            });
        }
      },

      async checkEmailExists(emailToCheck) {
        store.dialogLoader = true;

        await this.axios({
          method: "post",
          url: services.serviceHost + "/user/checkemail",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
          params: {
            email: emailToCheck
          }
        }).then(response => {
          console.log('check email, existe: ' + response.status);
          store.dialogLoader = false;
          if (response.status == 204) {
            return false;
          } else if (response.status == 200) {
            return true;
          }
        })
          .catch(error => {
            console.log('check email, error : ' + error.status);
            store.dialogLoader = false;
            return true;
          });
      },

      testaCPF(strCPF) {
        var Soma;
        var Resto;
        Soma = 0;
        //strCPF  = RetiraCaracteresInvalidos(strCPF,11);
        if (strCPF == "00000000000")
          return false;
        for (var i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11))
          Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)))
          return false;
        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11))
          Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11)))
          return false;
        return true;
      },

      isLetter(e) {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
      },

      simpleServiceCall(){
  
        const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
            .get(services.serviceHost + "/user/healthcheck", {}, config)
            .then(response => {
            console.log(response.data);
        });
      },

      checkTk(){
  
        const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
            .get(services.serviceHost + "/user/checktk", {}, config)
            .then(response => {
            console.log('token válido : ' + response.data);
            return true;
        })
        .catch(error => {
          console.log('token inválido : ' + error);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;
          return false;
        });
      },

      cleanLocalUserData(){

        var storage = window.localStorage;
        storage.setItem('username', null);
        storage.setItem('password', null);
      },
      
      softLogout(){

        store.userLogged = null;
        store.correntToken = null;
        store.countMyPedidos = null;
        store.countMyPropostas = null;
        store.myPropostasIDs = null;

        this.$router.push("/");
  
        this.axios({
          method: "post",
          url: services.serviceHost + "/oauth/logout",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
          data: {}
        }).then(response => {
          console.log('logout: ' + response.status);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;
          this.clearAllAndGoBackHome();

        })
        .catch(error => {
          console.log('token inválido : ' + error);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;
          this.clearAllAndGoBackHome();
        });
  
      },

      logout(){

        store.userLogged = null;
        store.correntToken = null;
        store.countMyPedidos = null;
        store.countMyPropostas = null;
        store.myPropostasIDs = null;

        this.$router.push("/");
  
        this.axios({
          method: "post",
          url: services.serviceHost + "/oauth/logout",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
          data: {}
        }).then(response => {
          console.log('logout: ' + response.status);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;
          this.cleanLocalUserData();
          this.$router.push("/");

        })
        .catch(error => {
          console.log('token inválido : ' + error);
          store.dialogLoader = false;
          store.userLogged = null;
          store.correntToken = null;
          this.cleanLocalUserData();
          this.$router.push("/");
        });
  
      },

      reloadUserData () {

        this.axios({
          method: "post",
          url: services.serviceHost + "/user/reload",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`
          },
        })
        .then(response => {
          store.userLogged = response.data;
          store.dialogLoader = false;
        });
      },
      
      formataMoedaBRL(valor){
          if(valor != null && valor != ''){
            return valor.toLocaleString('pt-br', {minimumFractionDigits: 2});
          }
          else{
            return new Number(0).toLocaleString('pt-br', {minimumFractionDigits: 2});
          }
      },

      formataMoedaUSD(valor){
        if(valor != null && valor != ''){
          return valor.toLocaleString('en-us', {minimumFractionDigits: 2});
        }
      },

      formatDecimalToUSD(valor, digits){
        if(valor != null && valor != ''){
          return valor.toLocaleString('en-us', {minimumFractionDigits: digits});
        }
        let zero = 0;
        return zero.toLocaleString('en-us', {minimumFractionDigits: digits});
      },

      formatDecimalToBRL(valor, digits){
        if(valor != null && valor != ''){
          return valor.toLocaleString('pt-br', {minimumFractionDigits: digits});
        }
      },

      buildNameForURL(myName){
        if(myName){
          return myName
            .normalize()
            .replaceAll(' > ', '/')
            .replaceAll(' ', '_')
            .replaceAll('.', '')
            .replaceAll(',', '')
            .replaceAll(':', '_')
            .replaceAll(')', '')
            .replaceAll('(', '')
            .replaceAll('"', '')
            .replaceAll('-', '')
            .toLowerCase()
        }
        return myName;
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      getHoraAgora(){
        return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5);
      },

      getHoje(){
        return this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10));
      },

      somaDias(myDate, diasParaSomar){

        myDate.setDate(myDate.toDate().getDate() + diasParaSomar);
        return myDate;
      },

      calculaIdade(dataNasc){ 
        
        if(dataNasc && dataNasc.indexOf('/') != -1){
          var dataAtual = new Date();
          var anoAtual = dataAtual.getFullYear();
          var anoNascParts = dataNasc.split('/');
          var diaNasc =anoNascParts[0];
          var mesNasc =anoNascParts[1];
          var anoNasc =anoNascParts[2];
          var idade = anoAtual - anoNasc;
          var mesAtual = dataAtual.getMonth() + 1; 
          //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
          if(mesAtual < mesNasc){
            idade--; 
          } else {
          //Se estiver no mes do nascimento, verificar o dia
            if(mesAtual == mesNasc){ 
              if(new Date().getDate() < diaNasc ){ 
              //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
              idade--; 
              }
            }
          } 
          return idade; 
        }else{
          return 0;
        }
      },

      getRolesAsList(myUser){
        let result = '';
        if(myUser && myUser.roles){
          for(var i = 0; i < myUser.roles.length; i++){
            result += myUser.roles[i].simpleName + ', '
          }
          return result.substring(0, result.length - 2);
        }
        return result;
      },

      abrePagina(myPage){
        window.open(myPage, '_blank');
      },
      

    },

    
}