<template>
    <div>
        <v-card :disabled="store.dialogLoader">
        <v-toolbar color="primary" title="Users">
            <v-spacer></v-spacer>
            <v-btn icon @click="addItem">
            <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <div>
            <v-row justify="end">
                <v-col cols="12" md="12" sm="12">
                <v-text-field
                    v-model="filters['global'].value"
                    placeholder="Filtrar..."
                    maxlength="10"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                />
                </v-col>
            </v-row>
            </div>
    
            <v-data-table
            :headers="headers"
            :items="itemList"
            :loading="loading"
            class="elevation-1"
            :search="filters['global'].value"
            :items-per-page="20"
            :footer-props="{
                'items-per-page-options': [5, 10, 20, 50],
            }"
            >
            <template #item="{ item }">
                <tr>
                <td>{{ item.fullName }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.lastLogin }}</td>
                <td>{{ item.dtCreated }}</td>
                <td class="text-center">
                    <v-btn icon @click="viewUserDetails(item)" flat>
                    <v-icon color="primary">mdi-magnify</v-icon>
                    </v-btn>
                </td>
                </tr>
            </template>
            </v-data-table>
        </v-card-text>
    
        <v-dialog v-model="showDetailsDialog" max-width="600px">
            <v-card>
            <v-card-title>
                <span class="headline">User Details</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                <v-col cols="6">
                    <v-text-field
                    label="Name"
                    v-model="selectedUser.fullName"
                    readonly
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                    label="Email"
                    v-model="selectedUser.email"
                    readonly
                    />
                </v-col>
                </v-row>
                <v-row>
                <v-col cols="6">
                    <v-text-field
                    label="Date of Birth"
                    v-model="selectedUser.userDetails.nascimento"
                    :value="selectedUser.userDetails ? selectedUser.userDetails.nascimento : ''"
                    readonly
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                    label="CPF"
                    v-model="selectedUser.userDetails.cpf"
                    :value="selectedUser.userDetails ? selectedUser.userDetails.cpf : ''"
                    readonly
                    />
                </v-col>
                </v-row>
                <v-row>
                <v-col cols="12">
                    <v-textarea
                    label="Phones"
                    v-model="selectedUser.userDetails.telefones"
                    :value="selectedUser.userDetails ? selectedUser.userDetails.telefones.map(t => t.numero).join(', ') : ''"
                    readonly
                    rows="2"
                    />
                </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="showDetailsDialog = false">Close</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        </v-card>

        <ModalUserInvite
        v-bind:show-dialog="showItemModal"
        @item-modal-close="itemModalClose"
        @item-modal-save="itemModalSave($event)"
        @update-show-dialog="updateShowDialog($event)"
        :key="selectedItem && selectedItem.id ? selectedItem.id : Math.random()"
        v-if="selectedItem"
        title-message="Enviar Convite"
        />
    </div>
  </template>
  
  <script>
  import { myMixin } from '../../mixin';
  import services from '../../ws/services';
  import { store } from '../../store';
  import ModalUserInvite from '@/components/modal/ModalUserInvite.vue';
  import User from '@/components/entity/User';
  
  export default {
    mixins: [myMixin],
    data: () => ({
      store,
      itemList: [],
      showDetailsDialog: false,
      selectedUser: null,
      loading: false,
      showItemModal: false,
      headers: [
        { title: 'Name', align: 'start', key: 'fullName' },
        { title: 'Email', align: 'start', key: 'email' },
        { title: 'Last Login', align: 'start', key: 'lastLogin' },
        { title: 'Created Date', align: 'start', key: 'dtCreated' },
        { title: 'Details', align: 'start', key: 'details' },
      ],
      filters: {
        global: { value: null },
      },
    }),
    mounted() {
      this.reloadItemList();
    },
    methods: {
      reloadItemList() {
        store.dialogLoader = true;
        this.axios
          .post(services.serviceHost + '/user/loadall', {}, {
            headers: { Authorization: `Bearer ${store.currentToken}` },
          })
          .then((response) => {
            this.itemList = response.data;
            store.dialogLoader = false;
          })
          .catch((error) => {
            console.log(error.message);
            store.dialogLoader = false;
            store.showDialogError = true;
            this.logout();
          });
      },
      viewUserDetails(user) {
        this.selectedUser = user;
        this.showDetailsDialog = true;
      },

      buildObject() {
        return new User();
      },
      addItem() {
        console.log('adicionando item');
        this.selectedItem = this.buildObject();
        this.showItemModal = true;
      },
      itemModalSave(myItem) {
        console.log('enviando convite: ' + myItem.firstName);
        this.showItemModal = false;
        this.selectedItem = null;
        store.dialogLoader = true;
        this.axios({
          method: "post",
          url: services.serviceHost + "/invite/save",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.currentToken}`,
          },
          data: myItem,
        })
          .then((response) => {
            console.log('salvou item: ' + response.data.id);
            store.dialogLoader = false;
            this.reloadItemList();
            store.globalSnackbarMessage = "Convite enviado";
            store.globalSnackbar = true;
          })
          .catch((error) => {
            console.log(error.message);
            this.showItemModal = false;
            store.showDialogError = true;
            this.logout();
          });
      },
      itemModalClose() {
        console.log('fechou a modal');
        this.showItemModal = false;
        this.selectedItem = null;
        this.reloadItemList();
      },
      updateShowDialog(val) {
        console.log('update show dialog: ' + val);
      },
    },
  };
  </script>
  
  <style scoped></style>
  