<template>
  <v-row class="px-1 mt-2">
    <v-col 
      cols="12"
    >
      <v-card
        v-if="isSmallScreen"
        class="elevation-0 mb-3 d-block d-sm-none"
        flat
      >
        <v-card-text class="px-2">
          <v-row justify="center">
            <v-col 
              cols="12"
              class="pb-0"
            >
              <v-btn
                width="100%"
                color="#767C77"
                flat
                class="text-white"
                rounded="0"
                @click="openNovoPedidoChild()"
              >
                Quero contratar um serviço
              </v-btn>
            </v-col>
            <v-col 
              cols="12"
            >
              <v-btn
                width="100%"
                color="#99A3A4"
                flat
                class="text-white"
                rounded="0"
                link
                to="/pedido/list/all"
              >
                Quero prestar serviços
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="elevation-5 mb-3 d-none d-sm-block">
        <v-card-text class="px-10">
          <v-row justify="center">
            <v-col 
              cols="12"
              sm="12"
              md="6"
              class="pr-sm-0"
            >
              <v-btn
                width="100%"
                color="#767C77"
                flat
                class="text-white"
                rounded="0"
                @click="openNovoPedidoChild()"
              >
                Quero contratar um serviço
              </v-btn>
            </v-col>
            <v-col 
              cols="12"
              sm="12"
              md="6"
              class="pl-sm-0"
            >
              <v-btn
                width="100%"
                color="#99A3A4"
                flat
                class="text-white"
                rounded="0"
                link
                to="/pedido/list/all"
              >
                Quero prestar serviços
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {
  mixins: [
    myMixin,
  ],

  emits: ['open-novo-pedido'], // Declare the emitted event

  data: () => ({
    endereco: null,
    errorMessage: null,
    store,
    isSmallScreen: false,
  }),

  computed: {},

  mounted() {
    this.isSmallScreen = this.$vuetify.display.mobile;
  },

  methods: {
    openNovoPedidoChild() {
      console.log('abrindo aqui novo pedido');
      this.$emit('open-novo-pedido');
    },

    simpleServiceCall() {
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .get(services.serviceHost + "/user/healthcheck", {}, config)
        .then(response => {
          console.log(response.data);
        });
      if (this.user && !this.user.id) {
        this.user.roles = [];
      }
    },
  },
}
</script>

<style scoped>
</style>
