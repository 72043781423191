<template>
  <CategoriaGrid />
</template>
  
<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';
import CategoriaGrid from '@/components/grid/CategoriaGrid.vue';

export default {
    components: { CategoriaGrid },
    mixins: [
        myMixin,
    ],

    data: () => ({

    }),
    computed: {
        cardTitleStyle() {
            return "background-color : " + this.$vuetify.theme.themes.light.colors.primary +
                "; color : white;";
        },
        btnCardStyle() {
            return "background-color : " + this.$vuetify.theme.themes.light.colors.primary +
                "; color : white;";
        },
    },

    mounted() {
        this.simpleServiceCall();
    },

    methods: {
        simpleServiceCall() {
            const config = {
                headers: { Authorization: `Bearer ${store.currentToken}` }
            };
            this.axios
                .get(services.serviceHost + "/user/healthcheck", {}, config)
                .then(response => {
                    console.log(response.data);
                });
            if (this.user && !this.user.id) {
                this.user.roles = [];
            }
        }
    }
}

</script>
  
<style scoped></style>
  