import { createRouter, createMemoryHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import LoginPage from '../views/LoginPage.vue'
import NewAccount from '../views/NewAccount.vue'
import RecoverPwd from '../views/RecoverPwd'
import UserInvite from '../views/UserInvite.vue'

import { store } from '../store';

import services from '@/ws/services';
import axios from 'axios';

//paginas do common:
import MyAccountData from '../views/common/MyAccountData'
import ConfirmaPedido from '../views/common/ConfirmaPedido'
import PedidoProposta from '../views/common/PedidoProposta'
import PropostaDetail from '../views/common/PropostaDetail'
import MeusPedidos from '../views/common/MeusPedidos'
import MinhasPropostas from '../views/common/MinhasPropostas'
import ListAllPedidos from '../views/common/ListAllPedidos'
import ListAllPedidosFiltered from '../views/common/ListAllPedidosFiltered'
import ListMyNotifications from '../views/common/ListMyNotifications'
import BankAccountDetail from '../views/common/BankAccountDetail'
import AddMoney from '../views/common/AddMoney'

//paginas de gerenciamento:
import ManageCategoria from '../views/admin/ManageCategoria'
import ManageServico from '../views/admin/ManageServico'
import ManageUsers from '../views/admin/ManageUsers'
import ListPropostas from '../views/admin/ListPropostas'
import ListPedidos from '../views/admin/ListPedidos'

//paginas do financeiro;
import UserTransactions from '@/views/financeiro/UserTransactions'
import DashboardFinanceiro from '@/views/financeiro/DashboardFinanceiro'

const router = createRouter({

  history: createMemoryHistory(),
  routes: [
    
    //páginas do common:
    {
      path: '/user/account/detail',
      name: 'myAccountData',
      component: MyAccountData,
    },
    {
      path: '/user/pedido/confirm',
      name: 'confirmaPedido',
      component: ConfirmaPedido,
    },
    {
      path: '/user/pedido/proposta',
      name: 'pedidoProposta',
      component: PedidoProposta,
    },
    {
      path: '/proposta/:propostaId',
      name: 'propostaDetail',
      component: PropostaDetail,
    },
    {
      path: '/user/pedido/list',
      name: 'meusPedidos',
      component: MeusPedidos,
    },
    {
      path: '/user/proposta/list',
      name: 'minhasPropostas',
      component: MinhasPropostas,
    },
    {
      path: '/pedido/list/all',
      name: 'listAllPedidos',
      component: ListAllPedidos,
    },
    {
      path: '/pedido/list/filter/:pedidoStatus',
      name: 'listAllPedidosFiltered',
      component: ListAllPedidosFiltered,
    },
    {
      path: '/user/notification/all',
      name: 'listMyNotifications',
      component: ListMyNotifications,
    },
    {
      path: '/user/bankaccount/all',
      name: 'bankAccountDetail',
      component: BankAccountDetail,
    },
    {
      path: '/user/bankaccount/addmoney',
      name: 'addMoney',
      component: AddMoney,
    },
    
    
    

    //paginas do admin:
    {
      path: '/admin/categoria',
      name: 'manageCategoria',
      component: ManageCategoria,
    },
    {
      path: '/admin/servico',
      name: 'manageServico',
      component: ManageServico,
    },
    {
      path: '/admin/users',
      name: 'manageUsers',
      component: ManageUsers,
    },
    {
      path: '/admin/proposta/list',
      name: 'listPropostas',
      component: ListPropostas,
    },
    {
      path: '/admin/pedido/list',
      name: 'listPedidos',
      component: ListPedidos,
    },


    //páginas do financeiro:
    {
      path: '/financeiro/transactions/all',
      name: 'userTransactions',
      component: UserTransactions,
    },
    {
      path: '/financeiro/dashboard',
      name: 'DashboardFinanceiro',
      component: DashboardFinanceiro,
    },

    
    {
      path: '/login',
      name: 'loginPage',
      component: LoginPage
    },
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/invite/:accessCode',
      name: 'userInvite',
      component: UserInvite,
      props: true,
    },
    {
      path: '/account/new',
      name: 'novaConta',
      component: NewAccount
    },
    {
      path: '/recover/:accessCode',
      name: 'recover',
      component: RecoverPwd,
      props: true,
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  
  console.log('entering before each');
  console.log(from.fullPath);

  if (
    to.name !== 'loginPage' &&
    to.name !== 'home' &&
    to.name !== 'novaConta' &&
    to.name !== 'recover' &&
    to.name !== 'userInvite'
  ) {
    const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` },
    };
    
    try {
      const response = await axios.post(services.serviceHost + "/user/checktk", {}, config);
      console.log('token válido : ' + response.status);
      next(); // Proceed to the route
    } catch (error) {
      console.log('token inválido : ' + error.status);
      store.dialogLoader = false;
      store.userLogged = null;
      store.correntToken = null;

      next({ name: 'loginPage' }); // Redirect to login page
    }
  } else {
    next(); // Proceed if the route is not guarded
  }
});


export default router
