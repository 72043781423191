<template>
  <v-card
    v-if="chartData"
    class=""
  >
    <Bar
      id="ChartPropostasPorDia"
      :options="chartOptions"
      :data="chartData"
    />
  </v-card>
</template>
  
<script>
//import { onMounted } from 'vue';
import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '@/store';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js/auto'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {

    name: 'ChartPropostasPorDia',
    components: { Bar },

    mixins: [
        myMixin,
    ],

    data() {
        return {
            store,

            chartData : null,
            // chartData: {
            //     labels: ['January', 'February', 'March'],
            //     datasets: [{ data: [40, 20, 12] }]
            // },
            chartOptions: {
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: 'Propostas / dia'
                    },
                    subtitle: {
                        display: true,
                        text: 'Período de 10 dias'
                    },
                    legend: {
                        display: false,
                        labels: {
                            color: 'blue'
                        },

                    }
                }
            }
        }
    },

    mounted(){
        this.loadReportData();
    },

    methods: {

        loadReportData() {

            store.dialogLoader = true;
            this.axios.get(services.serviceHost + "/chart/proposta/total/dia/10", {
                headers: { Authorization: `Bearer ${store.currentToken}` }
            })
                .then(response => {
                    console.log('pedidos chart: ' + JSON.stringify(response.data));

                    const data = response.data;
                    // Extract the dates and values from the response data
                    const dates = data.map(item => item.stringDate.substring(0, 5));
                    const values = data.map(item => item.total);

                    this.chartData = {
                        labels: dates,
                        datasets: [
                            {
                            label: 'Total',
                            data: values,
                            backgroundColor: 'rgba(36, 113, 163, 0.2)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                            },
                        ],
                    },

                    store.dialogLoader = false;
                })
                .catch(error => {
                    console.log('chart error : ' + error);
                    store.dialogLoader = false;
                    this.softLogout();
                });
        },
    }
}
</script>