<template>
  <v-card
    v-if="item"
    class="elevation-10 mt-6"
    :disabled="store.dialogLoader"
  >
    <v-toolbar color="primary">
      <v-toolbar-title>{{ item.servicoNome }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="px-5 pb-6">
      <v-row>
        <v-col
          cols="12"
          class="pb-1 text-body-1"
        >
          <div class="text-body-2">
            Pedido feito em {{ item.pedidoDtCreated }} hs
          </div>
          <div class="text-body-2">
            Proposta enviada em {{ item.dtCreated }} hs
          </div>
          <div
            v-if="item.acceptedPrestadorId"
            class="text-body-2"
          >
            Status da Proposta: 
            <span
              v-if="item.acceptedPrestadorId == item.prestadorId"
              class="font-weight-black"
              style="color : green;"
            >Aceita</span>
            <span
              v-else-if="item.acceptedPrestadorId != item.prestadorId"
              class="font-weight-black"
              style="color : orange;"
            >Recusada</span>
          </div>
        </v-col>

        <v-col
          cols="12"
          class="pt-1 pb-1"
        >
          <v-divider />
        </v-col>

        <v-col
          v-if="item.acceptedPrestadorId && item.acceptedPrestadorId == item.prestadorId"
          cols="12"
          class="pb-1 text-body-1"
        >
          <div class="">
            {{ item.endereco.logradouro }}<span>, {{ item.endereco.numero }} <span v-if="item.endereco.complemento">/ {{ item.endereco.complemento }}</span></span>
          </div>
          <div class="">
            {{ item.endereco.localidade }}, {{ item.endereco.bairro }}
          </div>
          <div>{{ item.enderecoShort.pontoReferencia }}</div>
        </v-col>

        <v-col
          v-else
          cols="12"
          class="pb-1 text-body-1"
        >
          <div
            v-if="item.acceptedPrestadorId && item.prestadorId != store.userLogged.id"
            class=""
          >
            {{ item.enderecoShort.logradouro }}<span>, {{ item.enderecoShort.numero }}</span>
          </div>
          <div v-if="item.aceita && item.prestadorId == store.userLogged.id && item.enderecoShort.complemento">
            {{ item.enderecoShort.complemento }}
          </div>
          <div class="">
            {{ item.enderecoShort.localidade }}, {{ item.enderecoShort.bairro }}
          </div>
          <div v-if="item.enderecoShort.pontoReferencia && item.aceita && item.prestadorId == store.userLogged.id">
            {{ item.enderecoShort.pontoReferencia }}
          </div>
        </v-col>

        <v-col
          cols="12"
          class="pt-1 pb-1"
        >
          <v-divider />
        </v-col>

        <v-col
          v-if="item.acceptedPrestadorId == item.prestadorId"
          cols="12" 
          class="pb-1 text-body-1"
        >
          <div>Dados do Contratante</div>
          <div class="">
            {{ item.nomeContratante }}
          </div>
          <div
            v-if="item.telefoneContratante"
            class=""
          >
            {{ item.telefoneContratante.numero }}
          </div>
        </v-col>

        <v-col
          v-if="item.acceptedPrestadorId == item.prestadorId"
          cols="12"
          class="pt-1 pb-1"
        >
          <v-divider />
        </v-col>

        <v-col
          cols="12"
          class="pb-1 text-body-1"
        >
          <div
            v-if="item.offeredPrice"
            class="font-weight-black"
          >
            Seu orçamento: R$ {{ formataMoedaBRL(item.offeredPrice) }}
          </div>
          <div
            v-if="item.pedidoOfferedValue"
            class="font-weight-black"
          >
            Contratante oferece: R$ {{ formataMoedaBRL(item.pedidoOfferedValue) }}
          </div>
        </v-col>
        
        <v-col
          cols="12"
          class="pt-1 pb-1"
        >
          <v-divider />
        </v-col>

        <v-col
          cols="12"
          class="pb-1"
        >
          <div>Descrição</div>
          <div class="font-italic">
            {{ item.pedidoDescricao }}
          </div>
        </v-col>
        
        <v-col
          cols="12"
          class="pt-1 pb-1"
        >
          <v-divider />
        </v-col>

        <v-col
          v-if="item.prestadorComment"
          cols="12"
          class="pb-1"
        >
          <div>Seu comentário</div>
          <div class="font-italic">
            {{ item.prestadorComment }}
          </div>
        </v-col>
        
        <v-col
          v-if="item.prestadorComment"
          cols="12"
          class="pt-1 pb-1"
        >
          <v-divider />
        </v-col>

        

        <!-- <v-col cols="12" class="pt-1 pb-1">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" class="pt-1 pb-1">
          <div class="" v-if="item.offeredPrice">Você paga R$ {{ formataMoedaBRL(item.offeredPrice) }}</div>
          <div class="" v-if="!item.offeredPrice">O prestador dará o preço</div>
        </v-col> -->

        
        
        <v-col
          v-if="endereco"
          cols="12"
          class="pt-1 pb-1"
        >
          <v-divider />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="">
      <v-spacer />

      <v-btn
        v-if="!item.acceptedPrestadorId"
        icon="mdi-trash-can"
        color="primary"
        elevation="0"
        @click="deleteProposta(item.id)"
      />
    </v-card-actions>
  </v-card>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {

  mixins: [
    myMixin,
  ],

  props : {
    item : Object
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen: true,
  }),

  computed : {
  },

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    this.simpleServiceCall();
  },

  methods: {

    deleteProposta(myItemId){

      this.$emit('deletar-proposta', myItemId);
    },

    openPropostaDetalhe(myItem){
      this.$emit('abrir-proposta-detalhe', myItem);
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
