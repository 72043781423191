<template>
  <div>
    <div id="topoLista" />

    <v-expand-transition>
      <v-card
        v-if="store.userLogged && store.userBankBalance && store.userBankBalance > 0" 
        class="elevation-5 mb-4 d-block d-sm-none font-weight-black"
        width="100%"
        color="grey"
      >
        <v-toolbar
          color="grey"
          class="pr-4"
        >
          <v-toolbar-title>
            <v-btn
              icon
              size="medium"
              link
              to="/user/bankaccount/all"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar-title>
          Seu saldo é de R$ {{ formataMoedaBRL(store.userBankBalance) }}
        </v-toolbar>
      </v-card>
    </v-expand-transition>

    <v-row v-if="pedidoList">
      <v-col cols="12">
        <my-current-location :item-list="pedidoList" />
      </v-col>
    </v-row>

    <v-card
      v-if="pedidoList && isSmallScreen"
      :disabled="store.dialogLoader"
      class="elevation-10 mb-6"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          variant="outlined"
          label="Filtrar"
          density="compact"
          clearable
        />
      </v-card-text>
    </v-card>

    <v-row class="">
      <v-col
        v-for="item in pedidoList"
        v-show="filterMyItem(item)"
        :key="item.id"
        cols="12"
        lg="3"
        md="4"
        sm="12"
        class=""
      >
        <card-pedido-info
          :key="item.id"
          :pedido-card-info="item"
          @open-dialog-pedido-detail="openDialogPedidoDetail($event)"
        />
      </v-col>
    </v-row>

    <!-- <box-pedido-mobile-list :item="item" v-for="item in pedidoList" :key="item.id" v-show="filterMyItem(item)"
      @deletar-pedido="deletePedido($event)" @abrir-pedido-detalhe="openPedidoDetalhe($event)"></box-pedido-mobile-list> -->

    <!-- <modal-pedido-detail-owner v-if="selectedPedido" :key="selectedPedido.id" @pedido-detalhe-modal-close="closeDetalheModal()"
      :item-id="null" :item-pedido="selectedPedido" :show-dialog="showDialogPedidoDetalhe" /> -->

    <v-expand-x-transition>
      <div
        v-if="position[1] > 80 && isSmallScreen"
        class="floating-button text-center"
      >
        <v-btn
          v-if="position[1] > 80"
          icon
          color="grey-darken-2"
          class="elevation-6" 
          @click="scrollToTheTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </v-expand-x-transition>

    <modal-pedido-detail
      v-if="selectedPedido"
      :key="selectedPedido.id"
      :item-id="null"
      :item-pedido="selectedPedido"
      :show-dialog="showDialogPedidoDetalhe"
      @pedido-detalhe-modal-close="closeDetalheModal()"
    />
  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import windowScrollPosition from '@/mixins/window-scroll-position';
import services from '../../ws/services';
import { store } from '../../store';

import CardPedidoInfo from '@/components/common/CardPedidoInfo.vue';
import MyCurrentLocation from '@/components/common/MyCurrentLocation.vue';
//import ModalPedidoDetailOwner from '@/components/modal/ModalPedidoDetailOwner.vue';
//import BoxPedidoMobileList from '@/components/common/BoxPedidoMobileList.vue';

import ModalPedidoDetail from '@/components/modal/ModalPedidoDetail.vue';

export default {

  components : {
    // ModalPedidoDetailOwner,
    // BoxPedidoMobileList,
    CardPedidoInfo,
    MyCurrentLocation,
    ModalPedidoDetail
  },

  mixins: [
    myMixin,
    windowScrollPosition('position'),
  ],

  data: () => ({

    headers : [
      { title: 'Serviço', align: 'start', key: 'servico', sortable: true},
      { title: 'Preço', align: 'start', key: 'originalPrice', sortable: true},
      { title: 'Local', align: 'start', key: 'endereco', sortable: true},
      { title: 'Data', key: 'dtCreated', sortable: true},
      { title: 'Propostas', align: 'center', key: 'propostaList', sortable: true},
      { title: 'Prestador', align: 'center', key: 'acceptedProposta', sortable: false},
      { title: 'Detalhe', align: 'center', key: 'id', sortable: false},

    ],
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    pedidoList : null,

    showDialogPedidoDetalhe : false,
    selectedPedido : null,

    search :  null,

  }),

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
  },

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;

  },  

  mounted() {
    this.loadBasicGlobalData();
    this.loadPedidos();
  },

  methods: {

    closeDetalheModal(){
      this.showDialogPedidoDetalhe = false;
      this.selectedPedido = null;
    },

    openDialogPedidoDetail(myPedido){
      console.log('opening dialog pedido 2 : ' + myPedido.servico.nomeServico);
      this.selectedPedido = myPedido;
      this.showDialogPedidoDetalhe = true;
    },

    deletePedido(myItemId){
      console.log('deletando pedido: ' + myItemId);

      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/delete/" + myItemId, {}, config)
        .then(response => {
          console.log('deletando o pedido: ' + response.status);
          store.globalSnackbarMessage = "Pedido deletado";
          store.globalSnackbar = true;
          this.loadPedidos();
      })
      .catch(error => {
        console.log('deletando pedido : ' + error.status);
        store.showDialogError = true;
        store.dialogLoader = false;
      });
    },

    filterMyItem(myItem){
      if(this.search && this.search.length >= 3){
        return myItem.dtCreated.toLowerCase().includes(this.search)
          || new String(myItem.originalPrice).toLowerCase().includes(this.search)
          || myItem.servico.nomeServico.toLowerCase().includes(this.search)
      }
      return true;
    },

    scrollToTheTop(){
      //window.scrollTo(0,0);

      const myEl = this.$refs.topoLista || this.$el || document.getElementById('topoLista');
      this.$smoothScroll({
        scrollTo: myEl,
        offset: -100,
      });
    },

    changeStatus(myItem){

      //changestatus/id
      console.log('mudando status: ' + myItem);
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/changestatus/" + myItem, {}, config)
        .then(response => {
          console.log('mudando status do pedido: ' + response.status);
          this.loadPedidos();
      })
      .catch(error => {
        console.log('mudando status do pedido : ' + error.status);
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },

    
    loadPedidos(){
        
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/allopenedenabled", {}, config)
        .then(response => {
          this.pedidoList = response.data;
          store.dialogLoader = false;
      })
      .catch(error => {
        console.log('load pedidos : ' + error.status);
        store.dialogLoader = false;
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },


    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
