export default class Pedido {
  
    constructor() {}
  
        id = null;
        endereco = null;
        valor = null;
        originalPrice = null;

        myPrice = false;
        theirPrice = false;

        description = null;
        servico = null;

        proposedValue = null;

        telefoneContato = null;
  }