<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        xl="3"
        lg="4"
        md="4"
        sm="8"
      >
        <v-dialog
          v-model="localShowDialog"
          transition="dialog-bottom-transition"
          :scrim="true"
          max-width="400"
          :fullscreen="isSmallScreen"
          @click:outside="closeModal"
        >
          <v-card
            v-if="selectedItem"
            :disabled="store.dialogLoader"
          >
            <v-toolbar
              color="primary"
            >
              <v-toolbar-title class="">
                {{ titleMessage }}
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                @click="closeModal"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="body-1 pa-6 pt-10">
              <v-text-field
                id="nomeCategoria"
                v-model="selectedItem.nomeCategoria" 
                class="mb-5"
                variant="outlined"
                label="Nome da Categoria"
                maxlength="30"
                clearable
                counter
                on-update:focused="true"
                :error-messages="v$.selectedItem.nomeCategoria.$invalid ? ['Digite uma categoria válida'] : []"
              />
            </v-card-text>

            <v-card-actions class="pa-5 pt-0">
              <v-spacer v-if="!isSmallScreen" />
              <v-btn
                color="primary"
                class="px-4"
                :disabled="v$.selectedItem.nomeCategoria.$invalid"
                :width="isSmallScreen ? '100%' : ''"
                large
                variant="outlined"
                @click="saveItem"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'

  import Categoria from '../entity/Categoria';
  
  export default {
  
    mixins: [
      myMixin,
    ],

    props: {
        titleMessage : String,
        itemId : Number,
        showDialog : Boolean,
    },

    setup () {
        return { v$: useVuelidate() }
    },
  
    data: () => ({
        store,
        nomeCategoria : null,
        selectedItem : null,

        isSmallScreen : false,
    }),

    computed : {
      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },
  
    beforeMount (){

      this.isSmallScreen = this.$vuetify.display.mobile;

      //this.selectedItem = null;
      if(this.itemId){

        store.dialogLoader = true;
        const config = {
              headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
              .post(services.serviceHost + "/categoria/load/" + this.itemId, {}, config)
              .then(response => {
              //console.log(response.data);
              this.selectedItem = response.data;
              store.dialogLoader = false;
          });
        }else{
          this.selectedItem = new Categoria();
        }
    },
  
    methods: {
  
      saveItem(){

        this.$emit('item-modal-save', this.selectedItem);
        this.selectedItem = null;
      },

      closeModal(){
        this.$emit('item-modal-close');
      },
  
    },

    validations() {
        return {
          selectedItem : {
            nomeCategoria : {
              required,
              min: minLength(3),
              max: maxLength(30),
            }
          }
        }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  