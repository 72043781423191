<template>
  <v-row>
    <v-col
      cols="12"
      xl="3"
      lg="4"
      md="4"
      sm="8"
    >
      <v-dialog 
        v-model="localShowDialog"
        transition="dialog-bottom-transition"
        :scrim="true"
        max-width="600"
        :fullscreen="isSmallScreen"
        persistent
      >
        <v-card
          v-if="selectedItem"
          :disabled="store.dialogLoader"
        >
          <v-toolbar
            color="primary"
            :title="selectedItem.servico.nomeServico"
          >
            <v-btn
              icon
              @click="closeModal"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-4 pt-4">
            <v-row>
              <v-col
                cols="12"
                class="pt-4 pb-1"
              >
                <div class="">
                  Criado em {{ selectedItem.dtCreated }} hs
                </div>
              </v-col>

              <v-col
                cols="12"
                class="pt-1 pb-1"
              >
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                class="pb-0 pt-4"
              >
                {{ selectedItem.endereco.logradouro }}
              </v-col>
              <v-col
                cols="12"
                class="pb-0 pt-0"
              >
                {{ selectedItem.endereco.bairro }}
              </v-col>
              <v-col
                cols="12"
                class="pb-0 pt-0"
              >
                {{ selectedItem.endereco.localidade }}, {{ selectedItem.endereco.uf }}
              </v-col>

              <v-col
                cols="12"
                class=""
              >
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                class="pb-0 pt-0 text-justified text-body-1"
              >
                {{ selectedItem.description }}
              </v-col>

              <v-col v-if="selectedItem.categorias"
                cols="12"
                class=""
              >
                <v-divider />
              </v-col>

              <v-col v-if="selectedItem.categorias"
                cols="12"
                class="pb-0 pt-0 text-justified text-body-1"
              >
                {{ selectedItem.categorias }}
              </v-col>

              <v-col
                cols="12"
                class=""
              >
                <v-divider />
              </v-col>

              <v-col
                v-if="selectedItem.myPrice"
                cols="12"
                class="pb-0 pt-0 text-justified font-weight-black"
              >
                Valor oferecido R$ {{ formataMoedaBRL(selectedItem.originalPrice) }}
              </v-col>

              <v-col
                v-else
                cols="12"
                class="pb-0 pt-0 text-justified"
              >
                Proponha seu valor.
              </v-col>

              <v-col
                cols="12"
                class=""
              >
                <v-divider />
              </v-col>
            </v-row>
          </v-card-text>

                  
          <v-card-actions
            v-if="store.userLogged && selectedItem.ownerId == store.userLogged.id"
            class="pa-5"
          >
            <v-spacer v-if="!isSmallScreen" />
            <v-btn
              variant="outlined"
              color="primary"
              class=""
              text
              width="100%"
            >
              Você criou esse Pedido
            </v-btn>  
          </v-card-actions>
          <v-card-actions
            v-else-if="store.myPropostasIDs && store.myPropostasIDs.includes(selectedItem.id) && store.userLogged && selectedItem.ownerId != store.userLogged.id"
            class="pa-5"
          >
            <v-spacer v-if="!isSmallScreen" />
            <v-btn
              variant="outlined"
              color="primary"
              class=""
              text
              width="100%"
            >
              Você já se candidatou
            </v-btn>  
          </v-card-actions>
          <!-- <v-card-actions class="pa-5" v-if="(!store.myPropostasIDs || !store.myPropostasIDs.includes(selectedItem.id)) && store.userLogged && (selectedItem.ownerId != store.userLogged.id)"> -->
          <v-card-actions
            v-else
            class="pa-5"
          >
            <v-spacer v-if="!isSmallScreen" />
            <v-btn
              v-if="selectedItem.myPrice"
              variant="outlined"
              color="primary"
              class=""
              text
              width="100%"
              @click="enviaProposta()"
            >
              Me candidatar
            </v-btn> 
            <v-btn
              v-else
              variant="outlined"
              color="primary"
              class=""
              text
              width="100%"
              @click="enviaProposta()"
            >
              Fazer Proposta
            </v-btn>  
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'
  
  export default {
  
    mixins: [
      myMixin,
    ],

    props: {
        titleMessage : String,
        itemId : Number,
        itemPedido : Object,
        showDialog : Boolean,
    },

    setup () {
        return { v$: useVuelidate() }
    },
  
    data: () => ({
        store,
        nomeCategoria : null,
        selectedItem : null,

        isSmallScreen : false,
    }),

    computed : {
      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },
  
    beforeMount (){
      //this.selectedItem = null;
      this.isSmallScreen = this.$vuetify.display.mobile;

      if(this.itemId){

        //store.dialogLoader = true;
        const config = {
              headers: { Authorization: `Bearer ${store.currentToken}` }
          };
          this.axios
              .post(services.serviceHost + "/pedido/load/" + this.itemId, {}, config)
              .then(response => {
              //console.log(response.data);
              this.selectedItem = response.data;
              //store.dialogLoader = false;
          });
        }else{
          this.selectedItem = this.itemPedido;
        }

        this.loadMyPedidosComPropostaID();
    },
  
    methods: {

      enviaProposta(){

        //console.log('salvando pedido : \n' + JSON.stringify(this.pedido));
        store.pedidoProposal = this.selectedItem;
        store.redirectPage = '/user/pedido/proposta';
        this.pedidoProposta();
      },
  
      saveItem(){

        this.$emit('item-modal-save', this.selectedItem);
        this.selectedItem = null;
      },

      closeModal(){
        this.$emit('pedido-detalhe-modal-close');
      },
  
    },

    validations() {
        return {
          selectedItem : {
            nomeCategoria : {
              required,
              min: minLength(3),
              max: maxLength(30),
            }
          }
        }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  