<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        xl="4"
        lg="6"
        md="6"
        sm="12"
      >
        <v-card
          v-if="store.pedidoProposal"
          class="elevation-5"
          :disabled="store.dialogLoader || dialogResultProposta"
        >
          <v-toolbar
            color="primary"
            :title="store.pedidoProposal.servico.nomeServico"
          >
            <v-btn
              icon
              @click="clearAllAndGoBackHome()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>  
          </v-toolbar>
          <v-card-text class="">
            <div class="text-body-1">
              {{ store.pedidoProposal.endereco.logradouro }}, {{ store.pedidoProposal.endereco.bairro }}
            </div>
            <div class="">
              {{ store.pedidoProposal.endereco.localidade }}, {{ store.pedidoProposal.endereco.uf }}
            </div>
            <div class="ma-5">
              <v-divider />
            </div>
            <div class="text-body-1 text-justified">
              {{ store.pedidoProposal.description }}
            </div>
            <div class="ma-5">
              <v-divider />
            </div>
            <div
              v-if="store.pedidoProposal.myPrice"
              class="text-body-1 text-center font-weight-black"
            >
              Valor: R$ {{ formataMoedaBRL(store.pedidoProposal.originalPrice) }}
            </div>
            <div
              v-if="!store.pedidoProposal.myPrice"
              class="text-center"
            >
              <v-btn
                flat
                color="primary"
                :size="selectedInputMoeda == 0 ? 'large' : 'x-large'"
                @click="addValorMoeda()"
              >
                {{ (selectedInputMoeda == 0 ? 'insira o valor da proposta' : 'R$ ' + formataMoedaBRL(selectedInputMoeda)) }}
                <!-- R$ {{ formataMoedaBRL(selectedInputMoeda) }} -->
              </v-btn>
            </div>

            <div class="mt-6">
              <v-textarea
                v-model="myMessage" 
                :disabled="store.myPropostasIDs.includes(store.pedidoProposal.id) || (store.userLogged && store.pedidoProposal.ownerId == store.userLogged.id)"
                label="Comentário (opcional)"
                maxlength="200"
                counter
                outlined
                clearable
              />
            </div>
          </v-card-text>

          <v-card-actions
            v-if="store.userLogged && store.pedidoProposal.ownerId == store.userLogged.id"
            class="pa-5"
          >
            <v-spacer v-if="!isSmallScreen" />
            <v-btn
              variant="outlined"
              color="primary"
              class=""
              text
              width="100%"
            >
              Você criou essa proposta
            </v-btn>  
          </v-card-actions>
          <v-card-actions
            v-else-if="store.myPropostasIDs.includes(store.pedidoProposal.id)"
            class="pa-5"
          >
            <v-spacer v-if="!isSmallScreen" />
            <v-btn
              variant="outlined"
              color="primary"
              class=""
              text
              width="100%"
            >
              Você já se candidatou
            </v-btn>  
          </v-card-actions>
          <!-- <v-card-actions class="pa-5" v-if="!store.myPropostasIDs.includes(store.pedidoProposal.id)"> -->
          <v-card-actions
            v-else
            class="pa-5"
          >
            <v-spacer />
            <v-btn
              v-if="store.pedidoProposal.myPrice"
              color="primary"
              class="px-4" 
              width="100%"
              variant="outlined" 
              @click="criaProposta()"
            >
              Me candidatar
            </v-btn>

            <v-btn
              v-if="!store.pedidoProposal.myPrice"
              color="primary"
              class="px-4" 
              width="100%"
              variant="outlined"
              :disabled="selectedInputMoeda <= 5"
              @click="criaProposta()"
            >
              Enviar Proposta
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>


      <box-input-valor-moeda
        :key="10"
        title-message="Escolher Valor"
        :show-dialog="showInputMoedaBox"
        :valor="selectedInputMoeda ? selectedInputMoeda : 50"
        @item-modal-valor-moeda-close="valorModalClose()"
        @item-modal-valor-moeda-save="valorMoedaModalSave"
      />   

      <v-dialog 
        v-model="dialogResultProposta"
        transition="dialog-bottom-transition"
        :scrim="true"
        max-width="600"
        persistent
        :fullscreen="isSmallScreen"
      >
        <v-card
          class=""
          color="primary"
        >
          <v-card-title
            v-if="!isSmallScreen"
            class="pa-4"
          >
            <v-icon left>
              mdi-emoticon-wink-outline
            </v-icon>
            Proposta enviada
          </v-card-title>
          <v-card-text class="text-center pa-6">
            <div class="mt-md-2 mt-sm-12">
              Proposta enviada com sucesso! Aguarde o contato do anunciante.
            </div>
            <div
              v-if="isSmallScreen"
              class="mt-12"
            >
              <v-icon size="50">
                mdi-emoticon-wink-outline
              </v-icon>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer v-if="!isSmallScreen" />
            <v-btn
              text
              color="white"
              variant="outlined"
              :width="isSmallScreen ? '100%' : ''"
              @click="closeDialogPropostaSaved()"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

import BoxInputValorMoeda from '@/components/common/BoxInputValorMoeda.vue';

export default {

  components: {
    BoxInputValorMoeda,

  },

  mixins: [
    myMixin,
  ],

  data: () => ({

    errorMessage: null,
    store,
    isSmallScreen: false,

    selectedInputMoeda: 0,
    showInputMoedaBox: false,

    myMessage: null,

    dialogResultProposta: false,
  }),

  computed: {

  },

  mounted() {
    this.isSmallScreen = this.$vuetify.display.mobile;
    //this.simpleServiceCall();
  },

  beforeMount() {
    if (!store.pedidoProposal) {
      this.$router.push("/");
    }
  },

  methods: {

    closeDialogPropostaSaved() {

      store.pedidoProposal = null;
      this.dialogResultProposta = false;
      this.$router.push('/');
    },

    criaProposta() {

      let proposta = {
        pedidoId: store.pedidoProposal.id,
        prestadorComment: this.myMessage,
        offeredPrice: (store.pedidoProposal.myPrice ? null : this.selectedInputMoeda)
      };
      console.log('enviando proposta: \n' + JSON.stringify(proposta));

      store.dialogLoader = true;
      this.axios({
        method: "post",
        url: services.serviceHost + "/proposta/create",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.currentToken}`
        },
        data: proposta
      }).then(response => {
        //console.log('proposta salva : \n' + JSON.stringify(response.data));
        console.log('proposta salva: ' + response.status);
        store.dialogLoader = false;
        this.dialogResultProposta = true;

        this.countMyPedidos();
        this.countMyPropostas();
      })
        .catch(error => {
          console.log('erro : ' + error.status);
          //console.log('error : ' + error);
          store.dialogLoader = false;
          //this.$router.push("/login");
          store.showDialogError = true;
        });
    },

    simpleServiceCall() {

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .get(services.serviceHost + "/user/healthcheck", {}, config)
        .then(response => {
          console.log(response.data);
        });
      if (this.user && !this.user.id) {
        this.user.roles = [];
      }

    },

    valorModalClose() {
      this.showInputMoedaBox = false;
    },

    addValorMoeda() {
      console.log('inserindo valor do serviço');
      this.showInputMoedaBox = true;
      this.selectedInputMoeda = 0;
    },

    valorMoedaModalSave(myValue) {
      console.log('valor salvo : ' + myValue);
      this.selectedInputMoeda = myValue;
      this.valorModalClose();
    }

  },

}

</script>

<style scoped>


</style>
