<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        xl="3"
        lg="4"
        md="4"
        sm="8"
      >
        <v-dialog
          v-model="localShowDialog"
          transition="dialog-bottom-transition"
          :scrim="true"
          max-width="500"
          :fullscreen="isSmallScreen"
          @click:outside="closeModal"
        >
          <v-card
            v-if="selectedItem"
            :disabled="store.dialogLoader"
          >
            <v-toolbar
              color="primary"
            >
              <v-toolbar-title class="">
                {{ titleMessage }}
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                @click="closeModal"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="body-1 pa-6 pt-10">
              <div class="body-1 mb-5 text-justify">
                Vamos enviar um email com o um link de convite. Assim que o convite for aceito, você também será avisado.
              </div>
                            
              <v-text-field
                id="firstName"
                v-model="selectedItem.firstName" 
                class="mb-5"
                variant="outlined"
                label="Nome do Convidado"
                maxlength="20"
                clearable
                counter
                on-update:focused="true"
                :error-messages="v$.selectedItem.firstName.$invalid ? ['Digite o nome'] : []"
                @keypress="isLetter($event)"
              />

              <v-text-field
                id="email"
                v-model="selectedItem.email" 
                class="mb-5"
                variant="outlined"
                label="Email"
                maxlength="120"
                clearable
                counter
                on-update:focused="true"
                :disabled="v$.selectedItem.firstName.$invalid"
                :error-messages="v$.selectedItem.email.$invalid ? ['Digite um email válido'] : []"
              />

              <v-checkbox
                v-if="store.userLogged.roleAdmin"
                v-model="selectedItem.admin" 
                label="Permissão de Administrador"
              />

              <v-checkbox
                v-if="store.userLogged.roleAdmin"
                v-model="selectedItem.atendimento" 
                label="Perfil de Financeiro"
                class="mt-n8"
              />
                            
              <v-checkbox
                v-if="store.userLogged.roleAdmin"
                v-model="selectedItem.financeiro" 
                label="Permissão de Atendimento"
                class="mt-n8"
              />
            </v-card-text>

            <v-card-actions class="pa-5 pt-0">
              <v-spacer v-if="!isSmallScreen" />
              <v-btn
                color="primary"
                class="px-4"
                :disabled="v$.selectedItem.$invalid"
                :width="isSmallScreen ? '100%' : ''"
                arge
                variant="outlined"
                @click="saveItem"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  //import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength, email } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'

  import User from '../entity/User';
  
  export default {

    components : {
      
    },
  
    mixins: [
      myMixin,
    ],

    props: {
        titleMessage : String,
        showDialog : Boolean,
    },

    setup () {
        return { v$: useVuelidate() }
    },
  
    data: () => ({
        store,
        firstName : null,
        selectedItem : null,

        isSmallScreen : false,
    }),

    computed : {
      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },  
  
    beforeMount (){

      this.isSmallScreen = this.$vuetify.display.mobile;
      this.selectedItem = new User();
    },
  
    methods: {
  
      saveItem(){

        let myObject = {
          godfather : store.userLogged,
          nomeInvite : this.selectedItem.firstName,
          admin : this.selectedItem.admin,
          atendimento : this.selectedItem.atendimento,
          financeiro : this.selectedItem.financeiro,
          email : this.selectedItem.email,
        }

        this.$emit('item-modal-save', myObject);
        this.selectedItem = null;
      },

      closeModal(){
        this.$emit('item-modal-close');
      },
  
    },

    validations() {
        return {
          selectedItem : {
            firstName : {
              required,
              min: minLength(3),
              max: maxLength(30),
            },
            email : {
              required, email
            }
          }
        }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  