<template>
    <v-card :disabled="store.dialogLoader">
        <v-toolbar color="primary">
      <v-toolbar-title>Serviços</v-toolbar-title>
      <v-btn icon @click="addItem">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-toolbar>
  
      <v-card-text class="pa-4">
        <v-data-table
          :headers="headers"
          :items="servicos"
          item-value="id"
          class="elevation-1"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.nomeServico }}</td>
              <td>{{ item.dtCreated }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-btn small v-bind="props" color="white" append-icon="mdi-magnify">
                      Categorias
                    </v-btn>
                  </template>
                  <div v-for="categoria in item.categoriaList" :key="categoria.id">
                    {{ categoria.nomeCategoria }}
                  </div>
                </v-tooltip>
              </td>
              <td>
                <v-btn icon @click="editItem(item)" small color="primary">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td>
                <v-switch
                  v-model="item.enabled"
                  @change="toggleEnabled(item)"
                  color="primary"
                  hide-details
                ></v-switch>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
  
      <v-dialog v-model="showEditDialog" max-width="600px">
        <v-card>
            <v-toolbar color="primary">
      <v-toolbar-title><span v-if="currentItem.id">Editar</span> <span v-else>Criar</span> Serviço</v-toolbar-title>
      <v-btn icon @click="closeEditDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>
          <v-card-text>
            <v-text-field
              v-model="currentItem.nomeServico"
              label="Nome do Serviço"
              required
            ></v-text-field>
            <v-select
              v-model="selectedCategories"
              :items="categorias"
              item-title="nomeCategoria"
              item-value="id"
              label="Categorias"
              multiple
              return-object
              required
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveItem">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </template>
  
  <script>
  import services from '../../ws/services';
  import { store } from '../../store';
  
  export default {
    data: () => ({
      store,
      servicos: [],
      categorias: [],
      showEditDialog: false,
      currentItem: null,
      selectedCategories: [],
      headers: [
        { title: 'Nome', align: 'start', key: 'nomeServico' },
        { title: 'Data de Criação', align: 'start', key: 'dtCreated' },
        { title: '', align: 'center', key: 'categorias', sorteable : false },
        { title: '', align: 'center', key: 'edit' },
        { title: 'Ativo', align: 'center', key: 'enabled' }, // New header for enabled status
      ],
    }),
    mounted() {
      this.fetchServicos();
      this.fetchCategorias();
    },
    methods: {
      fetchServicos() {
        const config = {
          headers: { Authorization: `Bearer ${store.currentToken}` },
        };
        store.dialogLoader = true;
        this.axios
          .post(services.serviceHost + '/servico/all', {}, config)
          .then((response) => {
            this.servicos = response.data;
            store.dialogLoader = false;
          })
          .catch((error) => {
            console.log(error);
            store.dialogLoader = false;
          });
      },
      fetchCategorias() {
        const config = {
          headers: { Authorization: `Bearer ${store.currentToken}` },
        };
        this.axios
          .post(services.serviceHost + '/categoria/all', {}, config)
          .then((response) => {
            this.categorias = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      addItem() {
        this.currentItem = { nomeServico: '', categoriaList: [] };
        this.selectedCategories = [];
        this.showEditDialog = true;
      },
      editItem(item) {
        this.currentItem = { ...item };
        this.selectedCategories = item.categoriaList; // Keep entire objects
        this.showEditDialog = true;
      },
      closeEditDialog() {
        this.showEditDialog = false;
      },
      saveItem() {
        const config = {
          headers: { Authorization: `Bearer ${store.currentToken}` },
        };
        this.currentItem.categoriaList = this.selectedCategories.map(categoria => ({
          id: categoria.id,
          nomeCategoria: categoria.nomeCategoria,
        }));
  
        this.axios
          .post(services.serviceHost + '/servico/save', this.currentItem, config)
          .then(() => {
            this.fetchServicos();
            this.closeEditDialog();
          })
          .catch((error) => {
            console.log(error);
          });
      },
      toggleEnabled(item) {
        // Update the enabled status for the service
        const config = {
          headers: { Authorization: `Bearer ${store.currentToken}` },
        };
  
        this.axios
          .post(services.serviceHost + '/servico/save', item, config)
          .then(() => {
            this.fetchServicos();
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  