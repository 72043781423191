<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        xl="3"
        lg="4"
        md="4"
        sm="8"
      >
        <v-dialog 
          v-model="localShowDialog"
          transition="dialog-bottom-transition"
          :scrim="true"
          max-width="600"
          :persistent="!isSmallScreen"
          :fullscreen="isSmallScreen"
        >
          <v-card
            v-if="servicoAvailableList && pedido"
            :disabled="store.dialogLoader"
          >
            <v-toolbar
              class=""
              color="primary"
            >
              <v-toolbar-title class="">
                {{ titleMessage }}
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                @click="closeModal"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="body-1 pa-6 pt-10">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="pedido.servico"
                    label="Do que você precisa"
                    :items="servicoAvailableList"
                    item-title="nomeServico"
                    return-object
                    clearable
                  />
                </v-col>

                <v-col
                  v-if="pedido && pedido.endereco"
                  cols="12"
                >
                  <!-- <box-endereco-info-full :endereco="endereco" v-if="endereco" boxTitle="titulo..." /> -->
                  <card-endereco-info
                    :key="pedido.endereco && pedido.endereco.id ? pedido.endereco.id : Math.random()"
                    title="Local do Serviço"
                    :current-endereco="pedido.endereco" 
                    :show-delete="true"
                    @box-endereco-info-remove="removeEnderecoPedido()"
                  />
                </v-col>

                <v-col
                  v-if="!pedido.endereco"
                  cols="12"
                  class="text-center"
                >
                  <v-btn
                    flat
                    color="primary"
                    :disabled="v$.pedido.servico.$invalid"
                    @click="addEnderecoItem()"
                  >
                    <v-icon left>
                      mdi-add
                    </v-icon>
                    Informar Endereço
                  </v-btn>
                </v-col>



                            

                <v-col cols="12">
                  <VDivider />
                </v-col>

                <v-col
                  v-if="pedido.endereco && pedido.endereco.logradouro"
                  cols="12"
                  class="mb-0"
                  transition="expand-transition"
                >
                  <v-text-field
                    id="telefone"
                    v-model="pedido.telefoneContato"
                    v-mask="'(##) ##### ####'"
                    class=""
                    color="primary"
                    prepend-inner-icon="mdi-cellphone-basic"
                    label="Telefone de Contato"
                    placeholder="Telefone"
                    maxlength="15"
                    type="numeric"
                    :error-messages="v$.pedido.telefoneContato.$invalid ? ['Digite um telefone válido'] : []"
                  />
                </v-col>

                <v-col
                  v-if="pedido.endereco && pedido.endereco.logradouro"
                  cols="12"
                  class="mb-0"
                  transition="expand-transition"
                >
                  <div class="mb-4">
                    Com relação ao valor:
                  </div>
                  <v-radio-group
                    v-model="valorServicoChoice"
                    @change="changePriceOption()"
                  >
                    <v-radio
                      class=""
                      label="Eu quero estabelecer o valor"
                      value="1"
                    />
                    <v-radio
                      label="Prefiro que o prestador dê o preço"
                      value="2"
                      @select="selectedInputMoeda = null"
                    />
                  </v-radio-group>
                </v-col>

                <v-col
                  v-if="valorServicoChoice == 1 && !selectedInputMoeda"
                  cols="12"
                  class="text-center pt-0"
                  transition="scale-transition"
                >
                  <v-btn
                    flat
                    color="primary"
                    :disabled="v$.pedido.servico.$invalid"
                    @click="addValorMoeda()"
                  >
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    Diz aqui o Valor
                  </v-btn>
                </v-col>

                <v-col
                  v-if="valorServicoChoice == 1 && selectedInputMoeda"
                  cols="12"
                  class="text-center pt-0"
                  transition="scale-transition"
                >
                  <v-btn
                    flat
                    color="primary"
                    size="x-large"
                    :disabled="v$.pedido.servico.$invalid"
                    @click="addValorMoeda()"
                  >
                    R$ {{ formataMoedaBRL(selectedInputMoeda) }}
                  </v-btn>
                </v-col>

                <v-col
                  v-if="(valorServicoChoice == 1 && selectedInputMoeda) || valorServicoChoice"
                  cols="12"
                  transition="scale-transition"
                >
                  <v-select :items="pedido.servico.categoriaList"
                    v-model="selectedCategories"
                    label="Características" item-title="nomeCategoria" item-value="nomeCategoria"
                    chips
                    multiple
                    />
                </v-col>

                <v-col
                  v-if="(valorServicoChoice == 1 && selectedInputMoeda) || valorServicoChoice"
                  cols="12"
                  transition="scale-transition"
                >
                  <v-textarea
                    v-model="pedido.description"
                    label="Insira uma descrição resumida do serviço"
                    maxlength="200"
                    counter
                    outlined
                    clearable
                    :error-messages="v$.pedido.description.$invalid ? ['Informe o que precisa ser feito'] : []"
                  />
                </v-col>
                <!-- <v-col cols="12">
                              <div>radio {{ valorServicoChoice }}, invalid : {{ v$.valorServicoChoice.$invalid }}</div>
                              <div>valor {{ selectedInputMoeda }}, invalid : {{ v$.selectedInputMoeda.$invalid }}</div>
                              <div>descr, invalid : {{ v$.pedido.description.$invalid }}</div>
                            </v-col> -->
              </v-row>
            </v-card-text>

            <v-card-actions class="pa-5 pt-0">
              <v-spacer v-if="!isSmallScreen" />
              <v-btn
                color="primary"
                class="px-4"
                :width="isSmallScreen ? '100%': ''"
                :disabled="v$.pedido.$invalid 
                  || v$.valorServicoChoice.$invalid 
                  || v$.pedido.description.$invalid"
                large
                variant="outlined"
                @click="prepareAndSavePedido()"
              >
                {{ (store.userLogged ? 'Salvar' : 'Fazer login e salvar') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <endereco-box
      v-if="pedido && pedido.endereco"
      :key="pedido.endereco && pedido.endereco.id ? pedido.endereco.id : Math.random()"
      :show-dialog="showEnderecoBox"
      :item-id="pedido.endereco.id"
      :title-message="pedido.endereco.id ? 'Editar Endereço' : 'Local'"
      @item-modal-close="enderecoModalClose"
      @item-modal-endereco-save="enderecoModalSave($event)"
    />

    <box-input-valor-moeda
      :key="10"
      title-message="Escolher Valor"
      :show-dialog="showInputMoedaBox"
      :valor="selectedInputMoeda ? selectedInputMoeda : 50"
      @item-modal-valor-moeda-close="valorModalClose()"
      @item-modal-valor-moeda-save="valorMoedaModalSave"
    />
  </div>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  //import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength, minValue } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'

  import Endereco from '../entity/Endereco';
  import EnderecoBox from '../desktop/EnderecoBox.vue';
  import CardEnderecoInfo from '../common/CardEnderecoInfo.vue'
  import BoxInputValorMoeda from '../common/BoxInputValorMoeda.vue'
  import Pedido from '../entity/Pedido';
  //import BoxEnderecoInfoFull from '../common/BoxEnderecoInfoFull.vue';
  
  export default {

    components : {
        EnderecoBox,
        CardEnderecoInfo,
        BoxInputValorMoeda,
        //BoxEnderecoInfoFull,
    },
  
    mixins: [
      myMixin,
    ],

    props: {
        titleMessage : String,
        showDialog : Boolean,
        servicoAvailableList : Array,
    },

    setup () {
        return { v$: useVuelidate() }
    },
  
    data: () => ({
        store,
        nomeCategoria : null,
        selectedItem : null,
        selectedCategories : null,

        valorServicoChoice : null,
        valorServico : null,

        showEnderecoBox : false,
        selectedEndereco : null,

        isSmallScreen: false,

        showInputMoedaBox : false,
        selectedInputMoeda : null,

        jobDescription : null,

        pedido : null,

        telefoneContato : null,
    }),

    computed : {

      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },

    mounted (){
      this.pedido = new Pedido();
      if(store.userLogged && store.userLogged.telefonePadrao){
        this.pedido.telefoneContato = store.userLogged.telefonePadrao.numero;
      }
      //this.pedido.endereco = new Endereco();

      this.isSmallScreen = this.$vuetify.display.mobile;
    },
  
    methods: {

      prepareAndSavePedido(){

        if(this.valorServicoChoice == 1){
          this.pedido.myPrice = true;
          this.pedido.originalPrice = this.selectedInputMoeda;
        }

        //console.log('salvando pedido : \n' + JSON.stringify(this.pedido));
        if(this.selectedCategories && this.selectedCategories.length > 0){
          this.pedido.categorias = this.selectedCategories.map(item => item.toLowerCase()).join(', ');
        }
        store.pedidoSaving = this.pedido;
        store.redirectPage = '/user/pedido/confirm';
        this.confirmaPedido();
      },

      changePriceOption(){
        if(this.valorServicoChoice == 2){
          this.selectedInputMoeda == null;
        }
      },

      removeEnderecoPedido(){
        console.log('removendo endereco');
        this.pedido.endereco = null;
      },  

      loadEndereco(){

        store.dialogLoader = true;
        if(this.endereco.cep && this.endereco.cep.length == 9){

          //https://opencep.com/v1/20531005.json
          this.axios
            .get('https://opencep.com/v1/' + this.endereco.cep.replaceAll('-', '') + '.json', {}, {})
            .then(response => {
            //console.log('resultado do CEP : \n' + JSON.stringify(response.data));
            store.dialogLoader = false;
            if(response.data){
              this.endereco = response.data;
            }
          })
          .catch(error => {
            console.log('token inválido : ' + error);
            store.dialogLoader = false;
          });
        }
      },
  
      saveItem(){

        this.$emit('item-modal-save', this.selectedItem);
        this.selectedItem = null;
      },

      closeModal(){
        this.endereco = null;
        this.$emit('item-modal-close');
      },

      addEnderecoItem(){
          console.log('adicionando endereco');
          this.pedido.endereco = new Endereco();
          this.showEnderecoBox = true;
      },
      
      enderecoModalClose(){
          console.log('fechou a modal');
          this.showEnderecoBox = false;
          //this.pedido.endereco = null;
      },

      enderecoModalSave(myEndereco){

        //console.log('salvando endereco: \n' + JSON.stringify(myEndereco));
        this.pedido.endereco = myEndereco;
        this.enderecoModalClose();
      },

      valorModalClose(){
        this.showInputMoedaBox = false;
      },

      addValorMoeda(){
        console.log('inserindo valor do serviço');
        this.showInputMoedaBox = true;
        this.selectedInputMoeda = 0;
      },

      valorMoedaModalSave(myValue){
        console.log('valor salvo : ' + myValue);
        this.selectedInputMoeda = myValue;
        this.valorModalClose();
      }
  
    },

    validations() {
      return {

        valorServicoChoice :{
          required : value => {
            if(value == 1){
              return !this.v$.selectedInputMoeda.$invalid;
            }else if (value == 2){
              return true;
            }
            return false;
          }
        },

        selectedInputMoeda : {
          required,
          minValue: minValue(50),
        },

        pedido : {

          telefoneContato : {
            required,
            min: minLength(15),
            max: maxLength(15),
          },

          servico : {
            required,
          },

          description : {
            required,
              min: minLength(15),
              max: maxLength(200),
          },

          endereco: {
            cep: {
              required,
              min: minLength(9),
              max: maxLength(9),
            },
            uf : {
              required,
              min: minLength(2),
              max: maxLength(2),
            },
            logradouro : {
              required,
              min: minLength(2),
              max: maxLength(200),
            },
            bairro : {
              required,
              min: minLength(2),
              max: maxLength(100),
            },
            localidade : {
              required,
              min: minLength(2),
              max: maxLength(100),
            },
            numero : {
              required,
              max: maxLength(10),
            }
          }
        }
      }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  