<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        xl="3"
        lg="4"
        md="4"
        sm="8"
      >
        <v-dialog 
          v-model="localShowDialog"
          transition="dialog-bottom-transition"
          :scrim="true"
          max-width="500"
          :fullscreen="isSmallScreen"
        >
          <v-card
            v-if="item"
            :disabled="store.dialogLoader"
          >
            <v-toolbar
              color="primary"
            >
              <v-toolbar-title class="">
                {{ item.servico.nomeServico }}
              </v-toolbar-title>
              <v-btn
                icon
                @click="closeModal"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="body-1 pa-4 pt-10">
              <v-row
                v-for="currentItem in item.propostaList"
                :key="currentItem.id"
              >
                <v-col
                  v-if="item.myPrice"
                  cols="12"
                  class="pb-0 pt-0"
                >
                  <div class="mt-2">
                    <span class="font-weight-black">Você ofereceu: R$ {{ formataMoedaBRL(item.originalPrice) }}</span>
                  </div>
                </v-col>
                <v-col
                  v-if="item.myPrice"
                  cols="12"
                  class=""
                >
                  <v-divider />
                </v-col>

                <v-col
                  cols="12"
                  class="pt-0 pb-0"
                >
                  <div class="">
                    <v-rating
                      v-model="mockRating"
                      half-increments
                      readonly
                      size="small"
                      color="primary"
                    />
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0 pt-0"
                >
                  <div class="">
                    {{ currentItem.prestadorNome }}, {{ currentItem.dtCreated }}
                  </div>
                  <div
                    v-if="item.acceptedProposta && item.acceptedProposta.prestadorTelefone"
                    class=""
                  >
                    Contato : {{ item.acceptedProposta.prestadorTelefone.numero }}
                  </div>
                </v-col>
                <v-col
                  v-if="currentItem.prestadorComment"
                  cols="12"
                  class="pb-0 pt-0"
                >
                  <div class="mt-2">
                    <span class="font-italic">"{{ currentItem.prestadorComment.trim() }}"</span>
                  </div>
                </v-col>
                <v-col
                  v-if="currentItem.offeredPrice"
                  cols="12"
                  class="pb-0 pt-0"
                >
                  <div class="mt-2">
                    <span class="font-weight-black">Orçamento: R$ {{ formataMoedaBRL(currentItem.offeredPrice) }}</span>
                  </div>
                </v-col>
                <v-col
                  v-if="!item.acceptedProposta"
                  cols="12"
                  class="pb-2 pt-4"
                >
                  <v-btn
                    text
                    width="100%"
                    color="primary"
                    variant="outlined"
                    prepend-icon="mdi-thumb-up-outline"
                    @click="confirmarProposta(currentItem)"
                  >
                    Fechar com {{ currentItem.prestadorFirstName }}
                  </v-btn>
                </v-col>
                <v-col
                  v-if="item.acceptedProposta"
                  cols="12"
                  class="pb-2 pt-2"
                >
                  <!-- Status dessa proposta : {{ currentItem.propostaStatusAsString }} -->
                  <!-- Status dessa proposta : {{ currentItem.propostaStatus }} -->
                  {{ item.acceptedProposta.id == currentItem.id ? 'Proposta aceita' : 'Proposta recusada' }}
                </v-col>

                <v-col
                  cols="12"
                  class=""
                >
                  <v-divider />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogConfirmAccept"
      transition="dialog-top-transition"
      persistent
      :scrim="true"
      max-width="500"
      :fullscreen="isSmallScreen"
    >
      <v-card v-if="item && selectedProposta">
        <v-toolbar
          color="primary"
        >
          <v-toolbar-title class="">
            {{ item.servico.nomeServico }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="dialogConfirmAccept = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-h5 text-center pt-12">
          <div>Aceitar proposta de</div>
          <div class="">
            {{ selectedProposta.prestadorNome }}
          </div>
          <div class="font-weight-black mt-4">
            <v-icon
              size="60"
              color="primary"
            >
              mdi-handshake-outline
            </v-icon>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            width="100%"
            color="primary"
            variant="outlined"
            @click="aceitarProposta()"
          >
            Aceitar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
  
  import { myMixin } from '../../mixin';
  //import services from '../../ws/services';
  import { store } from '../../store';
  import { required, minLength, maxLength } from '@vuelidate/validators'
  import useVuelidate from '@vuelidate/core'
  
  export default {
  
    mixins: [
      myMixin,
    ],

    props: {
        titleMessage : String,
        item : Object,
        showDialog : Boolean,
    },

    setup () {
        return { v$: useVuelidate() }
    },
  
    data: () => ({
        store,
        nomeCategoria : null,
        selectedItem : null,

        isSmallScreen : false,

        mockRating : 3.5,

        selectedProposta : null,
        dialogConfirmAccept : false,
    }),

    computed : {
      localShowDialog : {
        get: function () {
            return this.showDialog;
        },
        set: function (val) {
            this.$emit('update-show-dialog', val);
        }
      }
    },
  
    beforeMount (){

      this.isSmallScreen = this.$vuetify.display.mobile;
    },
  
    methods: {

      confirmarProposta(myitem){
        this.selectedProposta = myitem;
        this.dialogConfirmAccept = true;
      },

      aceitarProposta(){
        this.$emit('pedido-proposta-aceitar', this.selectedProposta);
        this.dialogConfirmAccept = false;
        this.selectedProposta = null;
      },
  
      saveItem(){

        this.$emit('item-modal-save', this.selectedItem);
        this.selectedItem = null;
      },

      closeModal(){
        this.$emit('modal-list-propostas-pedido-close');
      },
  
    },

    validations() {
        return {
          selectedItem : {
            nomeCategoria : {
              required,
              min: minLength(3),
              max: maxLength(30),
            }
          }
        }
    }
  
  }
  
  </script>
  
  <style scoped>
  
  
  </style>
  