<template>
  <div>
    <div id="topoLista" />

    <v-card
      v-if="propostaList && isSmallScreen"
      :disabled="store.dialogLoader"
      class="elevation-10"
    >
      <v-toolbar color="primary">
        <v-toolbar-title>
          Você enviou {{ store.myPropostasCount }} 
          <span v-if="store.myPropostasCount == 0">proposta</span>
          <span v-if="store.myPropostasCount > 0">propostas</span>
        </v-toolbar-title>
        <v-btn
          icon
          large
          link
          to="/"
          color="white"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-text-field 
          v-model="search" 
          append-inner-icon="mdi-magnify"
          variant="outlined"
          label="Filtrar"
          density="compact"
          clearable
        />
      </v-card-text>
    </v-card>

    <div v-if="propostaList && isSmallScreen">
      <box-proposta-mobile-list
        v-for="item in propostaList"
        v-show="filterMyItem(item)"
        :key="item.id"
        :item="item"
        @deletar-proposta="cancelarProposta($event)" 
        @abrir-pedido-detalhe="openPropostaDetalhe($event)"
      />
    </div>

    <v-card
      v-if="propostaList && !isSmallScreen"
      :disabled="store.dialogLoader"
    >
      <v-toolbar color="primary">
        <v-toolbar-title>Você enviou {{ store.myPropostasCount }} propostas</v-toolbar-title>
        <v-btn
          icon
          large
          link
          to="/"
          color="white"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-card class="pa-2 mb-4">
          <v-text-field 
            v-model="search"
            append-inner-icon="mdi-magnify"
            variant="outlined"
            label="Filtrar"
            density="compact"
            clearable
          />
        </v-card>
        
        <v-data-table
          items-per-page="15"
          :headers="headers"
          :items="propostaList"
          class="elevation-1"
          :search="search"
        >
          <template #[`item.dtCreated`]="{ item }">
            {{ item.dtCreated }}
          </template>

          <template #[`item.aceita`]="{ item }">
            <span v-if="item.aceita && item.prestadorId == store.userLogged.id">Aceita</span>
            <span v-else-if="item.aceita && item.prestadorId != store.userLogged.id">Fechada</span>
            <span v-else>Em aberto</span>
          </template>

          <template #[`item.offeredPrice`]="{ item }">
            {{ (item.offeredPrice ? 'R$ ' + formataMoedaBRL(item.offeredPrice) : 'R$ ' + formataMoedaBRL(item.pedidoOfferedValue)) }} 
          </template>

          <template #[`item.nomeContratante`]="{ item }">
            <v-btn
              v-if="item.aceita && item.nomeContratante"
              variant="outlined"
              append-icon="mdi-magnify"
              class="text-none"
              color="primary"
              size="small"
              link
              :to="'/proposta/' + item.id"
            >
              {{ item.nomeContratante }}
            </v-btn>
            <span v-if="!item.aceita || !item.nomeContratante">-</span>
          </template>
        
          <template #[`item.endereco`]="{ item }">
            {{ (item.enderecoShort ? item.enderecoShort.bairro : '-') }}
          </template>

          <template #[`item.cancelar`]="{ item }">
            <v-btn
              variant="text"
              x-small
              color="primary"
              :disabled="item.aceita"
              @click="cancelarProposta(item.id)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-expand-x-transition>
      <div
        v-if="position[1] > 80 && isSmallScreen"
        class="floating-button text-center"
      >
        <v-btn
          v-if="position[1] > 80"
          icon
          color="grey-darken-2"
          class="elevation-6" 
          @click="scrollToTheTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </v-expand-x-transition>
  </div>
</template>


<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

import BoxPropostaMobileList from '@/components/common/BoxPropostaMobileList.vue';

import windowScrollPosition from '@/mixins/window-scroll-position';

export default {

  components : {
    BoxPropostaMobileList,
  },

  mixins: [
    myMixin,
    windowScrollPosition('position'),
  ],

  data: () => ({

    headers : [
      { title: 'Serviço', key: 'servicoNome', sortable: true},
      { title: 'Status', key: 'aceita', sortable: true},
      { title: 'Contratante', key: 'nomeContratante', sortable: true},
      { title: 'Local', key: 'endereco', sortable: true},
      { title: 'Valor', key: 'offeredPrice', sortable: true},
      { title: 'Data', key: 'dtCreated', sortable: true},
      { title: 'Cancelar', key: 'cancelar', sortable: true},
    ],
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    propostaList : null,

    search :  null,

  }),

  computed : {
    
  },

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;

  },  

  mounted() {
    this.loadPropostas();
    this.loadBasicGlobalData();
  },

  methods: {

    openPropostaDetalhe(myItem){
      this.openDialogPedido(myItem);
    },

    cancelarProposta(myItemId){
      console.log('deletando proposta: ' + myItemId);

      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/proposta/delete/" + myItemId, {}, config)
        .then(response => {
          console.log('deletando proposta: ' + response.status);
          store.globalSnackbarMessage = "Proposta deletada";
          store.globalSnackbar = true;
          this.loadPropostas();
      })
      .catch(error => {
        console.log('deletando proposta : ' + error.status);
        store.showDialogError = true;
        store.dialogLoader = false;
      });
    },

    filterMyItem(myItem){
      if(this.search && this.search.length >= 3){
        return myItem.dtCreated.toLowerCase().includes(this.search)
          || myItem.servicoNome.toLowerCase().includes(this.search)
      }
      return true;
    },

    scrollToTheTop(){
      //window.scrollTo(0,0);

      const myEl = this.$refs.topoLista || this.$el || document.getElementById('topoLista');
      this.$smoothScroll({
        scrollTo: myEl,
        offset: -100,
      });
    },

    changeStatus(myItem){

      //changestatus/id
      console.log('mudando status: ' + myItem);
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/changestatus/" + myItem, {}, config)
        .then(response => {
          console.log('mudando status do pedido: ' + response.status);
          this.loadPropostas();
      })
      .catch(error => {
        console.log('mudando status do pedido : ' + error.status);
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },

    
    loadPropostas(){
        
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/proposta/allbyowner", {}, config)
        .then(response => {
          this.propostaList = response.data;
          store.dialogLoader = false;
      })
      .catch(error => {
        console.log('load pedidos : ' + error.status);
        store.dialogLoader = false;
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },


    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
