export default class Endereco {
  
    constructor() {}
  
        id = null;
        tipo = null;
        cep = null;
        logradouro = null;
        complemento = null;
        bairro = null;
        localidade = null;
        uf = null;
        cobranca = null;
        faturamento = null;
        ibge = null;
        pontoReferencia = null;
        numero = null;
        tipo = 'Residencial';
  
  }