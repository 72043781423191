<template>
  <div>
    <div v-if="notificationList && isSmallScreen">
      <div class="text-right">
        <v-btn
          class=""
          size="small"
          color="primary"
          variant="outlined"
          icon
          link
          to="/"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    
      <v-list density="compact">
        <v-list-item
          v-for="(item, index) in notificationList"
          :key="item.id"
          lines="3"
          link
          :to="item.messageLink"
        >
          <v-list-item-title class="mb-2">
            <v-icon
              v-if="!item.viewed"
              color="primary"
            >
              mdi-message-alert
            </v-icon>
            <v-icon
              v-if="item.viewed"
              color="grey-lighten-2"
            >
              mdi-message-alert-outline
            </v-icon>
            {{ item.dtCreatedFriendly }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ item.messageText }}</v-list-item-subtitle>
          <v-list-item-action
            v-if="index < (notificationList.length - 1)"
            class="pt-2 pb-2"
          >
            <v-divider />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  
    <div v-if="notificationList && !isSmallScreen">
      <v-row justify="center">
        <v-col
          cols="12"
          lg="8"
          md="10"
        >
          <v-card>
            <v-toolbar color="primary">
              <v-toolbar-title>Notificações</v-toolbar-title>
              <v-btn
                icon
                large
                link
                to="/"
                color="white"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="pa-2">
              <v-card
                v-for="item in notificationList"
                :key="item.id"
                class="elevation-5 mt-4 mb-4 pa-4"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="1"
                    sm="12"
                    class=""
                  >
                    <v-icon
                      v-if="!item.viewed"
                      color="primary"
                    >
                      mdi-message-alert
                    </v-icon>
                    <v-icon
                      v-if="item.viewed"
                      color="grey-lighten-2"
                    >
                      mdi-message-alert-outline
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="12"
                    class=""
                  >
                    {{ item.dtCreated }}
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                    sm="12"
                    class=""
                  >
                    {{ item.messageText }}
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    sm="12"
                    class=""
                  >
                    <v-btn
                      v-if="item.messageLink"
                      icon
                      x-small
                      size="x-small"
                      color="white"
                      variant="outlined"
                      link
                      :to="item.messageLink"
                    >
                      <v-icon color="primary">
                        mdi-magnify
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>


          <!-- <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn variant="outlined" color="primary"
              class="" text :width="isSmallScreen ? '100%' : ''">
              Click here</v-btn>
          </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {

  mixins: [
    myMixin,
  ],

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : null,

    notificationList : null,
  }),

  computed : {
    
  },

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    //this.loadBasicGlobalData();
    this.loadNotificacoes();
    this.markAllNotificationsRead();
  },

  methods: {

    loadNotificacoes(){
        
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/notification/all", {}, config)
        .then(response => {
          console.log(JSON.stringify(response.data));
          this.notificationList = response.data;
          store.dialogLoader = false;
      })
      .catch(error => {
        console.log('load pedidos : ' + error.status);
        store.showDialogError = true;
      });
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  },

}

</script>

<style scoped>


</style>
