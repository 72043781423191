<template>
  <div>
    <v-card
      v-if="propostaList"
      :disabled="store.dialogLoader"
    >
      <v-toolbar color="primary">
        <v-toolbar-title>Total de {{ propostaList.length }} Propostas</v-toolbar-title>
        <v-btn
          icon
          large
          link
          to="/"
          color="white"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-card class="pa-2 mb-4">
          <v-text-field
            v-model="filter"
            append-inner-icon="mdi-magnify"
            variant="outlined"
            label="Filtrar"
            density="compact"
            clearable
          />
        </v-card>

        <!-- Standard Vuetify v-data-table -->
        <v-data-table
          :items="propostaList"
          :headers="headers"
          :search="filter"
          items-per-page="15"
          class="elevation-1"
        >
          <template #[`item.dtCreated`]="{ item }">
            {{ item.dtCreated }}
          </template>

          <template #[`item.aceita`]="{ item }">
            <v-btn
              v-if="item.acceptedPrestadorId"
              variant="outlined"
              append-icon="mdi-magnify"
              class="text-none"
              color="primary"
              size="small"
              :to="'/proposta/' + item.id"
            >
              <span v-if="item.acceptedPrestadorId == item.prestadorId">Aceita</span>
              <span v-if="item.acceptedPrestadorId != item.prestadorId">Recusada</span>
            </v-btn>
            <span v-if="!item.acceptedPrestadorId">Em aberto</span>
          </template>

          <template #[`item.offeredPrice`]="{ item }">
            {{ (item.offeredPrice ? 'R$ ' + formataMoedaBRL(item.offeredPrice) : 'R$ ' + formataMoedaBRL(item.pedidoOfferedValue)) }}
          </template>

          <template #[`item.nomeContratante`]="{ item }">
            {{ item.nomeContratante ? item.nomeContratante : '-' }}
          </template>

          <template #[`item.prestadorNome`]="{ item }">
            {{ item.prestadorNome ? item.prestadorNome : '-' }}
          </template>

          <template #[`item.endereco`]="{ item }">
            {{ (item.enderecoShort ? item.enderecoShort.bairro + ', ' + item.enderecoShort.uf : '-') }}
          </template>

          <template #[`item.cancelar`]="{ item }">
            <v-btn
              variant="text"
              x-small
              color="primary"
              :disabled="item.aceita"
              @click="cancelarProposta(item.id)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

export default {

  components : {
    
  },

  mixins: [
    myMixin,
  ],

  data: () => ({

    headers : [
      { title: 'Serviço', key: 'servicoNome', sortable: true},
      { title: 'Status', key: 'aceita', sortable: true},
      { title: 'Contratante', key: 'nomeContratante', sortable: true},
      { title: 'Prestador', key: 'prestadorNome', sortable: true},
      { title: 'Local', key: 'endereco', sortable: true},
      { title: 'Valor', key: 'offeredPrice', sortable: true},
      { title: 'Data', key: 'dtCreated', sortable: true},
      { title: 'Cancelar', key: 'cancelar', sortable: true},
    ],
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    propostaList : null,

    filter : null,

  }),

  computed : {
    cardTitleStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
    btnCardStyle (){
      return 'background-color : ' + this.$vuetify.theme.themes.light.colors.primary + 
      '; color : white;';

    },
  },

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;

  },  

  mounted() {
    this.loadPropostas();
    this.loadBasicGlobalData();
  },

  methods: {

    cancelarProposta(myItemId){

      console.log('cancelando proposta : ' + myItemId);
    },

    changeStatus(myItem){

      //changestatus/id
      console.log('mudando status: ' + myItem);
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/changestatus/" + myItem, {}, config)
        .then(response => {
          console.log('mudando status do pedido: ' + response.status);
          this.loadPropostas();
      })
      .catch(error => {
        console.log('mudando status do pedido : ' + error.status);
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },

    
    loadPropostas(){
        
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/proposta/all", {}, config)
        .then(response => {
          this.propostaList = response.data;
          store.dialogLoader = false;
      })
      .catch(error => {
        console.log('load pedidos : ' + error.status);
        store.dialogLoader = false;
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },


    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    }

  }

}

</script>

<style scoped>


</style>
