export default class User {
  
    constructor() {}
  
        id = null;
        firstName = null;
        lastName = null;
        email = null;

        password = null;
        confirmPassword = null;

        userDetails = null;


        admin = false;
  }