<template>
  <div class="fill-height">
    <v-card
      v-if="pedidoCardInfo"
      class="pb-2 fill-height"
      elevation="5"
      :disabled="store.dialogLoader"
    >
      <v-toolbar
        class="small"
        :color="store.userLogged && store.myPropostasIDs.includes(pedidoCardInfo.id) ? 'grey-darken-2' : 'primary'"
        :title="pedidoCardInfo.servico.nomeServico"
      >
        <v-btn
          icon
          @click="openDialogPedido(pedidoCardInfo)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-4">
        <v-row>
          <v-col
            cols="12"
            class="pt-2 pb-0"
          >
            {{ pedidoCardInfo.endereco.bairro }}, {{ pedidoCardInfo.endereco.localidade }}  
          </v-col>

          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <v-divider class="mt-2 mb-2" />
          </v-col>

          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            {{ (pedidoCardInfo.description && pedidoCardInfo.description.length > 100 ? pedidoCardInfo.description.substring(0, 100) + ' ... ' : pedidoCardInfo.description) }}
          </v-col>

          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <v-divider class="mt-2 mb-2" />
          </v-col>

          <v-col
            v-if="pedidoCardInfo.myPrice"
            cols="12"
            class="pt-0 font-weight-black"
          >
            Valor oferecido R$ {{ formataMoedaBRL(pedidoCardInfo.originalPrice) }}
          </v-col>

          <v-col
            v-else
            cols="12"
            class="pt-0"
          >
            Você propõe o valor
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';

//import ModalPedidoDetail from '../modal/ModalPedidoDetail.vue';

export default {

  components : {
    //ModalPedidoDetail,
  },

  mixins: [
    myMixin,
  ],

  props : {
    pedidoCardInfo : Object,
    showDelete : Boolean,
  },

  data: () => ({
      
    errorMessage : null,
    store,

    showDialogPedidoDetalhe : false,
  }),

  computed : {
  },

  mounted() {
    //this.simpleServiceCall();
  },

  methods: {

    openDialogPedido(myPedido){
      //this.showDialogPedidoDetalhe = true;
      console.log('opening dialog pedido : ' + myPedido.servico.nomeServico);
      this.$emit('open-dialog-pedido-detail', myPedido);
    },

    closeDetalheModal(){
      this.showDialogPedidoDetalhe = false;
    },

    simpleServiceCall(){

      const config = {
      headers: { Authorization: `Bearer ${store.currentToken}` }
    };
    this.axios
      .get(services.serviceHost + "/user/healthcheck", {}, config)
      .then(response => {
        console.log(response.data);
      });
      if(this.user && !this.user.id){
        this.user.roles = [];
      }
    },

    removeEndereco(){
      this.$emit('box-endereco-info-remove');
    },

  }

}

</script>

<style scoped>


</style>
