
import { reactive } from 'vue'

export const store = reactive({
  
    userLogged : null,
    correntToken : null,
    isAuthenticated : false,

    dialogLoader : false,
    dialogRecoverPwd : false,
    globalSnackbar : false,
    globalSnackbarMessage : null,

    showDialogError : false,
    
    //dados reativos de modais:
    showDialogCategoria : false,

    pedidoSaving : null,
    pedidoProposal : null,
    redirectPage : null,

    notificationUnreadList : [],
    totalNotificationsUnread : 0,
    
    myPedidosCount : 0,
    myPropostasCount : 0,
    myPropostasIDs : [],

    pedidosEmAndamentoList : [],
    pedidosEmExecucaoList : [],

    //dados do bank:
    totalUserTransactions : 0,
    userBankBalance : 0,
})
