<template>
  <div>
    <div id="topoLista" />

    <v-card
      v-if="pedidoList && isSmallScreen"
      :disabled="store.dialogLoader"
      class="elevation-10 mb-4"
    >
      <v-toolbar color="primary">
        <v-toolbar-title>Você abriu {{ store.myPedidosCount }} Pedidos</v-toolbar-title>
        <v-btn
          icon
          large
          link
          to="/"
          color="white"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          variant="outlined"
          label="Filtrar"
          density="compact"
          clearable
        />
      </v-card-text>
    </v-card>

    <div v-if="pedidoList && isSmallScreen">
      <box-pedido-mobile-list 
        v-for="item in pedidoList" 
        v-show="filterMyItem(item)" 
        :key="item.id" 
        :item="item"
        @list-propostas-pedido="listaPropostasDoPedido($event)" 
        @iniciar-servico="iniciarServico($event)"
        @finish-job="finishJob($event)"
        @deletar-pedido="deletePedido($event)" 
        @abrir-pedido-detalhe="openPedidoDetalhe($event)"
      />
    </div>

    <v-card
      v-if="pedidoList && !isSmallScreen"
      :disabled="store.dialogLoader"
    >
      <v-toolbar color="primary">
        <v-toolbar-title>Você abriu {{ store.myPedidosCount }} Pedidos</v-toolbar-title>
        <v-btn
          icon
          large
          link
          to="/"
          color="white"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-card class="pa-2 mb-4">
          <v-text-field
            v-model="search"
            append-inner-icon="mdi-magnify"
            variant="outlined"
            label="Filtrar"
            density="compact"
            clearable
          />
        </v-card>

        <v-data-table
          items-per-page="15"
          :headers="headers"
          :items="pedidoList"
          class="elevation-1"
          :search="search"
        >
          <template #[`item.servico`]="{ item }">
            {{ item.servico.nomeServico }}
          </template>

          <template #[`item.originalPrice`]="{ item }">
            <span v-if="item.originalPrice">{{ 'R$ ' + formataMoedaBRL(item.originalPrice) }}</span>
            <span v-else-if="!item.originalPrice && item.acceptedProposta && item.acceptedProposta.offeredPrice">{{ 'R$ ' + formataMoedaBRL(item.acceptedProposta.offeredPrice) }}</span>
            <span v-else>-</span>
          </template>

          <template #[`item.endereco`]="{ item }">
            {{ item.endereco.logradouro }}
          </template>

          <template #[`item.propostaList`]="{ item }">
            <span v-if="item.propostaList.length == 0">{{ item.propostaList.length }}</span>
            <v-btn
              v-if="item.propostaList.length > 0"
              text
              size="small"
              color="primary"  
              variant="outlined"
              prepend-icon="mdi-magnify"
              @click="listaPropostasDoPedido(item)"
            >
              {{ item.propostaList.length }}
            </v-btn>
          </template>

          <template #[`item.acceptedProposta`]="{ item }">
            {{ (item.acceptedProposta ? item.acceptedProposta.prestadorNome : 'em aberto') }}
          </template>

          <template #[`item.id`]="{ item }">
            <v-btn
              icon
              variant="outlined"
              x-small
              size="x-small"
              color="primary"
              @click="openDialogPedido(item)"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <modal-pedido-detail-owner 
      v-if="selectedPedido" 
      :key="selectedPedido.id" 
      :item-id="null"
      :item-pedido="selectedPedido" 
      :show-dialog="showDialogPedidoDetalhe" 
      @pedido-detalhe-modal-close="closeDetalheModal()" 
    />

    <modal-list-propostas-do-pedido 
      v-if="selectedPedido" 
      :key="selectedPedido.id" 
      :show-dialog="showDialogListPropostasDoPedido"
      :item="selectedPedido" 
      @modal-list-propostas-pedido-close="closeModalListPropostasDoPedido()" 
      @pedido-proposta-aceitar="aceitarProposta($event)"
    />

    <v-dialog 
      v-model="showDialogPropostaSuccess" 
      persistent 
      transition="dialog-top-transition"
      max-width="400" 
      :fullscreen="isSmallScreen" 
      :disabled="store.dialogLoader"
    >
      <v-card :disabled="dialogLoader">
        <v-toolbar color="primary">
          <v-toolbar-title>Parabéns!</v-toolbar-title>
          <v-btn
            icon
            large
            color="white"
            @click="closeDialogPropostaSuccess()"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-8 text-center">
          <div>Estamos enviando os dados do prestador para o seu e-mail.</div>
          <div class="mt-4">
            Você também pode visualizar os dados das suas propostas aceitas a qualquer momento, no menu <span class="font-weight-black">"Meus Pedidos"</span>.
          </div>
          <div class="mt-6">
            <v-icon
              size="50"
              color="primary"
            >
              mdi-handshake-outline
            </v-icon>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="px-4"
            width="100%"
            large
            variant="outlined"
            @click="closeDialogPropostaSuccess()"
          > 
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-expand-x-transition>
      <div
        v-if="position[1] > 80 && isSmallScreen"
        class="floating-button text-center"
      >
        <v-btn
          v-if="position[1] > 80"
          icon
          color="grey-darken-2"
          class="elevation-6"
          @click="scrollToTheTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </v-expand-x-transition>
  </div>
</template>


<script>

import { myMixin } from '../../mixin';
import windowScrollPosition from '@/mixins/window-scroll-position';
import services from '../../ws/services';
import { store } from '../../store';

import ModalPedidoDetailOwner from '@/components/modal/ModalPedidoDetailOwner.vue';
import ModalListPropostasDoPedido from '@/components/modal/ModalListPropostasDoPedido.vue';

import BoxPedidoMobileList from '@/components/common/BoxPedidoMobileList.vue';

export default {

  components : {
    ModalPedidoDetailOwner,
    BoxPedidoMobileList,
    ModalListPropostasDoPedido,
  },

  mixins: [
    myMixin,
    windowScrollPosition('position'),
  ],

  data: () => ({

    headers : [
      { title: 'Serviço', align: 'start', key: 'servico', sortable: true},
      { title: 'Preço', align: 'start', key: 'originalPrice', sortable: true},
      { title: 'Local', align: 'start', key: 'endereco', sortable: true},
      { title: 'Data', key: 'dtCreated', sortable: true},
      { title: 'Propostas', align: 'center', key: 'propostaList', sortable: true},
      { title: 'Prestador', align: 'center', key: 'acceptedProposta', sortable: false},
      { title: 'Detalhe', align: 'center', key: 'id', sortable: false},
      //{ title: 'Ativo', align: 'center', key: 'enabled', sortable: false},

    ],
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    pedidoList : null,

    showDialogPedidoDetalhe : false,
    showDialogListPropostasDoPedido : false,
    selectedPedido : null,
    showDialogPropostaSuccess : false,

    search :  null,

  }),

  computed : {

  },

  beforeMount (){

    this.isSmallScreen = this.$vuetify.display.mobile;

  },  

  mounted() {
    this.loadPedidos();
    this.loadBasicGlobalData();
  },

  methods: {

    async finishJob(myPedido){

      console.log('finalizando job do pedido: ' + myPedido.id + ', rating: ' + myPedido.rating);
      let myData = {
        pedidoId : myPedido.id,
        score : myPedido.rating
      }

      const changedPedido = await this.finishJobPedido(myData);
      console.log('finishJobPedido pedido, new status : ' + changedPedido.pedidoStatus);

      const index = store.pedidosEmExecucaoList.findIndex(item => item.id === changedPedido.id);
      store.pedidosEmExecucaoList.splice(index, 1, changedPedido);
      this.loadPedidos();
    },

    async iniciarServico(myPedido){

      try{
        console.log('iniciando servico do pedido ' + myPedido.id);
        const changedPedido = await this.startJobPedido(myPedido);
        console.log('changed pedido, new status : ' + changedPedido.pedidoStatus);
        //console.log('VUE INSTANCE : ' + this.$root);

        const index = store.pedidosEmAndamentoList.findIndex(item => item.id === changedPedido.id);
        //this.pedidoList.splice(index, 1, changedPedido);
        store.pedidosEmAndamentoList.splice(index, 1, changedPedido);
        this.loadPedidos();
        //await this.loadMyPedidosAceitos();

      }catch(error){
        console.log('******* PROBLEMA COM START DO JOB: \n' + error);
      }
    },

    closeDialogPropostaSuccess(){
      this.showDialogPropostaSuccess = false;
      this.loadPedidos();
    },

    aceitarProposta(myProposta){
      console.log('aceitando proposta : ' + myProposta.id);

      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/proposta/accept/" + myProposta.id + '/' + myProposta.pedidoId, {}, config)
        .then(response => {
          console.log('proposta salva : ' + response.status);
          store.dialogLoader = false;
          this.closeModalListPropostasDoPedido();
          this.showDialogPropostaSuccess = true;

          //verificamos se ficou tudo ok:
          if(response.status == 200){
            this.showDialogPropostaSuccess = true;
          }else{
            store.showDialogError = true;
          }

      })
      .catch(error => {
        console.log('proposta salva : ' + error.status);
        store.dialogLoader = false;
        this.closeModalListPropostasDoPedido();
        store.showDialogError = true;
      });
    },

    listaPropostasDoPedido(myItem){
      console.log('abrindo propostas do pedido : ' + myItem.id);
      this.selectedPedido = myItem;
      this.showDialogListPropostasDoPedido = true;
    },

    closeModalListPropostasDoPedido(){
      this.selectedPedido = null;
      this.showDialogListPropostasDoPedido = false;
    },

    openPedidoDetalhe(myItem){
      this.openDialogPedido(myItem);
    },

    deletePedido(myItemId){
      //console.log('deletando pedido: ' + myItemId);

      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/delete/" + myItemId, {}, config)
        .then(response => {
          console.log('deletando o pedido: ' + response.status);
          store.globalSnackbarMessage = "Pedido deletado";
          store.globalSnackbar = true;
          this.loadPedidos();
      })
      .catch(error => {
        console.log('deletando pedido : ' + error.status);
        store.showDialogError = true;
        store.dialogLoader = false;
      });
    },

    filterMyItem(myItem){
      if(this.search && this.search.length >= 3){
        return myItem.dtCreated.toLowerCase().includes(this.search)
          || new String(myItem.originalPrice).toLowerCase().includes(this.search)
          || myItem.servico.nomeServico.toLowerCase().includes(this.search)
      }
      return true;
    },

    scrollToTheTop(){
      //window.scrollTo(0,0);

      const myEl = this.$refs.topoLista || this.$el || document.getElementById('topoLista');
      this.$smoothScroll({
        scrollTo: myEl,
        offset: -100,
      });
    },

    openDialogPedido(myItem){
      //console.log('abrindo detalhe do pedido, ' + myItem.id);
      this.selectedPedido = myItem;
      this.showDialogPedidoDetalhe = true;
    },

    closeDetalheModal(){
      this.showDialogPedidoDetalhe = false;
      this.selectedPedido = null;
    },

    changeStatus(myItem){

      //changestatus/id
      console.log('mudando status: ' + myItem);
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/changestatus/" + myItem, {}, config)
        .then(response => {
          console.log('mudando status do pedido: ' + response.status);
          this.loadPedidos();
      })
      .catch(error => {
        console.log('mudando status do pedido : ' + error.status);
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },

    
    loadPedidos(){
        
      store.dialogLoader = true;
      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/pedido/allbyowner", {}, config)
        .then(response => {
          this.pedidoList = response.data;
          store.dialogLoader = false;
      })
      .catch(error => {
        console.log('load pedidos : ' + error.status);
        store.dialogLoader = false;
        store.userLogged = null;
        store.correntToken = null;
        this.$router.push("/");
      });
    },
  }

}

</script>

<style scoped>


</style>
