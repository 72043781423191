// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { createVuetify } from 'vuetify'

//provisorios:
//import { VDataTable } from 'vuetify/labs/VDataTable'

// Vuetify
export default createVuetify({

  // components: {
  //   VDataTable,
  // },

  customProperties: true,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#03a9f4',
          secondary: '#2196f3',
          accent: '#795548',
          error: '#f44336',
          warning: '#ffc107',
          info: '#8bc34a',
          success: '#4caf50'
        }
      },
      dark: {
        colors: {
          primary: '#B79E5B',
          secondary: '#c8d8e4',
          accent: '#795548',
        }
      }
    }
  }
})

// export default createVuetify({
//   theme: {
//     primary: '#B79E5B',
//     secondary: '#c8d8e4',
//     accent: '#795548',


//     // defaultTheme : 'myDark',
//     // themes: {
//     //   myLight: {
//     //     //primary: '#f19020',
//     //     primary: '#B79E5B',
//     //     secondary: '#c8d8e4',
//     //     accent: '#795548',
//     //   },
//     //   myDark: {
//     //     //primary: '#44732F',
//     //     primary: '#52ab98',
//     //     secondary: '#44732F',
//     //     accent: 'black',
//     //   }
//     // }
//   }

// })
